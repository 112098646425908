import { Component, OnInit, computed, effect, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { PropertiesService } from '../../data-access/properties.service';
import { filter, map, switchMap, tap } from 'rxjs';
import { FeatureLayersService } from 'src/app/map/features/property/features/feature-layers/data-access/feature-layers.service';
import { MapService } from 'src/app/map/data-access/map.service';

@Component({
  selector: 'app-property-details',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './property-details.component.html',
  styleUrl: './property-details.component.css'
})
export class PropertyDetailsComponent implements OnInit {

  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);
  private readonly propertiesService: PropertiesService = inject(PropertiesService);
  private readonly mapService:MapService = inject(MapService);
  private readonly featureLayerService: FeatureLayersService = inject(FeatureLayersService);


  private routeParams;
  private propertyID;
  private propertyQuery;

  public property = toSignal(this.propertiesService.activeProperty.asObservable());
  validID

  constructor()
  {
     this.routeParams  = toSignal(this.route.params, {})

     this.propertyID = computed( () => this.routeParams().id ? this.routeParams().id == ':id' ? '' : this.routeParams().id : '');


     this.validID = computed(() => this.propertyID() == '' ? false : true);





     effect(() => {

      if(!this.validID())
      {
         if(this.property().propertyID != null)
         {
          this.router.navigate([`/map/property`, this.property().propertyID]);
         }

         return;
      }

      this.propertiesService.get(this.propertyID()).subscribe(data => {
          console.log(this.property())
      });


      this.featureLayerService.setPropertyID(this.propertyID());
      this.featureLayerService.loadLayers();


     })
  }

  ngOnInit(): void {

  }

  onClick()
{
  this.router.navigate([`/map/property`, this.propertyID(), 'digitize']);
}

zoomTo()
{
  this.mapService.zoomToFeature(this.propertyID(), 10);
}

edit()
{

  this.propertiesService.get(this.propertyID()).pipe(
    switchMap(property => this.propertiesService.editProperty(property)),
    filter(result => result.update),
    switchMap(result => this.propertiesService.update(this.propertyID(),result.model)),
    switchMap(() => this.propertiesService.get(this.propertyID()))
    ).subscribe(property =>
      {

      })
}

}
