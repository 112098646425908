<div class="title-bar">
  <div class="title">Delete {{data.type}}</div>
  <div class="window-controls">
      <button ><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content">
  <p>Are you sure you want to delete <b>{{data.details}}</b>?</p>
</div>
<div class="footer">
  <button  class="button button--muted button--xs" (click)="onCancel()">Cancel</button>
  <button class="button button--danger button--xs" (click)="onConfirm()">Yes</button>
</div>
