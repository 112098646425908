import { Component, ViewChild, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { Draw } from 'ol/interaction';
import { Circle as CircleGeom  } from 'ol/geom';
import { FeatureLayersService } from '../../data-access/feature-layers.service';
import { Feature, Map as olMap } from 'ol';
import VectorSource from 'ol/source/Vector';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { DrawEvent } from 'ol/interaction/Draw';
import { filter, map, switchMap } from 'rxjs';
import { createResult } from '../../ui/create-feature-dialog/create-feature-dialog.component';
import { MaterialModule } from 'src/app/material/material.module';
import { NgxColorsModule } from 'ngx-colors';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { Circle, Fill, Image, Stroke, Style } from 'ol/style';
import OlParser from 'geostyler-openlayers-parser';
import { Style as geoStyle } from 'geostyler-style'
import { MapService } from '../../../../../../data-access/map.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { CircleToPolygon } from 'src/app/shared/helpers/transformations';
import {CdkListbox, CdkOption} from '@angular/cdk/listbox';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';

@Component({
  selector: 'app-draw-features-overlay',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    CdkListbox,
    CdkOption,
    TooltipDirective
  ],
  templateUrl: './draw-features-overlay.component.html',
  styleUrl: './draw-features-overlay.component.css'
})
export class DrawFeaturesOverlayComponent {


  setDrawState(drawType: string) {

    this.test() === drawType ? this.test.set(null) : this.test.set(drawType);
}


  @ViewChild(CdkListbox) listbox: CdkListbox;


  public readonly featureLayerService: FeatureLayersService = inject(FeatureLayersService);
  private readonly mapService: MapService = inject(MapService);
  private readonly modalService:ModalService = inject(ModalService);

  drawTypeDefinitions = [
    {drawType: 'Point', icon: ' fas fa-map-marker-alt'},
    {drawType: 'Polygon', icon: 'fas fa-draw-polygon'},
    {drawType: 'Circle', icon: 'far fa-circle'},
    {drawType: 'LineString', icon: 'fas fa-minus'},
  ];

  test = signal(null);

  layerSelected = computed(() => this.featureLayerService.currentlySelectedLayerID() == null ? false : true);

  layerEditing = computed(() => this.featureLayerService.currentlyEditingLayer )

  private readonly olParser = new OlParser();

   fillColor: WritableSignal<string> = signal('rgba(0, 0, 255, 0.1)');
   strokeColor: WritableSignal<string> = signal('rgba(0, 0, 255, 1)');

  selectedLayerState = signal(null);
  drawType = computed(() => this.featureLayerService.currentlyEditingLayer() ? null : this.test());
  drawInteraction: Draw;

  map: olMap

  constructor() {


    this.map = this.mapService.getMap();

    effect(async () => {


      console.log(this.drawType());

      if(this.drawType() == null)
      {
        this.map.removeInteraction(this.drawInteraction);
        return;
      }


      if(this.drawInteraction)
      {
        this.map.removeInteraction(this.drawInteraction);
      }

      let style = new Style({
        fill: new Fill({color: this.fillColor()}),
        stroke: new Stroke({color: this.strokeColor()}),
        image: new Circle({fill: new Fill({color: this.strokeColor()}),radius:5})
      });

      this.drawInteraction = new Draw({
        type: this.drawType(),
        source: new VectorSource(),
        style: style
      })

      this.drawInteraction.on('drawend', (event:DrawEvent) => {

        this.test.set(null);

        let feature = event.feature;

        if(feature.getGeometry() instanceof CircleGeom)
        {
          let circleGeom = CircleToPolygon(feature.getGeometry(), 100)
          feature.setGeometry(circleGeom);
        }

        this.modalService.showCreateFeature(event.feature).pipe(filter((data: createResult) => data.shouldCreate),switchMap(data => this.featureLayerService.createFeature(data.model))).subscribe();
      });

     this.map.addInteraction(this.drawInteraction)
    });



    // const pointStyle: geoStyle = {
    //   name: 'My Point Style',
    //   rules: [
    //     {
    //       name: 'Small Population',
    //       filter: ['<', 'population', 100000],
    //       symbolizers: [
    //         {
    //           kind: "Fill",
    //           color: "#0E1058"
    //         }
    //       ]
    //     },
    //     {
    //       name: 'Large Population',
    //       filter: ['>=', 'population', 100000],
    //       symbolizers: [
    //         // Define symbolizers for large population
    //       ]
    //     }
    //   ]
    // };


    // this.olParser.writeStyle(pointStyle).then(data => console.log(data.output))


  }



}
