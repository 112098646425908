import { Component, EventEmitter, OnDestroy, OnInit, Output, computed, effect, inject, signal } from '@angular/core';
import { Feature } from 'ol';
import MVT from 'ol/format/MVT';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { Style, Fill, Stroke } from 'ol/style';
import { min } from 'rxjs';
import { MapService } from 'src/app/map/data-access/map.service';
import { Union } from 'src/app/shared/helpers/transformations';
import { CreatePropertyEvent } from '../digitize-property-overlay/digitize-property-overlay.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-property-cadastre-overlay',
  templateUrl: './select-property-cadastre-overlay.component.html',
  styleUrls: ['./select-property-cadastre-overlay.component.css']
})
export class SelectPropertyCadastreOverlayComponent implements OnInit, OnDestroy {

  @Output() createEvent = new EventEmitter<CreatePropertyEvent>();

  private readonly mapService: MapService = inject(MapService);

  private readonly minZoom = 10;

  private cadasterLayer;
  private selectionLayer;

  selection: Map<any, Feature[]> = new Map();

   selectedFeatureStyle = new Style({
    fill: new Fill({
      color: 'rgba(242,247,250,0.5)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,16,16)',
      width: 2,
    }),
  });


  isAtRequiredZoom = signal(false);

  addLayerEffect = effect(() => {

    if(this.isAtRequiredZoom() &&  this.mapService.getMap().getLayers().getArray().indexOf(this.cadasterLayer) === -1)
    {
      this.mapService.getMap().addLayer(this.cadasterLayer);
      this.mapService.getMap().addLayer(this.selectionLayer);
    }
    else
    {
      this.mapService.getMap().removeLayer(this.cadasterLayer);
      this.mapService.getMap().removeLayer(this.selectionLayer);
    }

  }, {allowSignalWrites:true});

  constructor() { }


  ngOnDestroy(): void {
    this.mapService.getMap().removeLayer(this.cadasterLayer);
    this.mapService.getMap().removeLayer(this.selectionLayer);
  }

  ngOnInit() {

    const format = new MVT({featureClass: Feature,  idProperty: 'cad_pid',});

    this.cadasterLayer = new VectorTileLayer({
      source: new VectorTileSource({
          format: format,
          url: environment.apiUrl + 'cadastre/{z}/{x}/{y}',
          maxZoom: 14,
          minZoom:this.minZoom,
      }),
  });

  this.selectionLayer = new VectorTileLayer({
    renderMode: 'vector',
    source: this.cadasterLayer.getSource(),
    style: (feature) => {
      if (this.selection.has(feature.getId())) {
        return this.selectedFeatureStyle;
      }
      return null
    },
  });

  let atRequiredZoom = this.calculateIfAtRequiredZoom(this.mapService.map.getView().getZoom(), this.minZoom);

  this.isAtRequiredZoom.set(atRequiredZoom);

  this.mapService.map.on('moveend', () => {
    let atRequiredZoom = this.calculateIfAtRequiredZoom(this.mapService.map.getView().getZoom(), this.minZoom);

    this.isAtRequiredZoom.set(atRequiredZoom);
  });




  this.mapService.map.on(['click'], (event: any) =>  {

    this.cadasterLayer.getFeatures(event.pixel).then( (features)  => {
      if (!features.length) {
        this.selectionLayer.changed();
        return;
      }
      const feature = features[0];
      if (!feature) {
        return;
      }
      const fid = feature.getId();

      let _features = this.cadasterLayer.getSource().getFeaturesInExtent(this.mapService.getMapExtent());

    _features = _features.filter((ft) => ft.getId() == fid);

      this.selection.set(fid, _features);

      this.selectionLayer.changed();
    });
  });

  }

  private calculateIfAtRequiredZoom(currentZoom, minZoom)
  {
    if(currentZoom >= minZoom)
    {
      return true
    }
    else
    {
     return false
    }
  }

  finished() {
     let selectedFeatures: Array<Feature<any>[]> = Array.from(this.selection.values());

    let mergedFeatures = selectedFeatures.map(features => Union(features))

    let propertyBoundary = Union(mergedFeatures);

    this.createEvent.emit(CreatePropertyEvent.create(propertyBoundary))

    }
    undo() {
      let keys = Array.from(this.selection.keys());

      let last = keys.pop();

      this.selection.delete(last);
      this.selectionLayer.changed();
    }
    cancel() {
      this.createEvent.emit(CreatePropertyEvent.cancel())
    }


}
