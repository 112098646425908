<div class="overlay">
  <p>Digitize the polygon/s, once done click the (tick)</p>
  <div class="grid--column gap--xs">
    <button class="button button--danger" (click)="cancel()"><span class="fa fa-times"></span></button>
    <button class="button button--muted" (click)="undo()"><span class="fa fa-undo"></span></button>
    <button class="button button--blue" [disabled]="propertyBoundary == null" (click)="finished()"><span class="fa fa-check"></span></button>

  </div>

</div>
