import { Component, OnInit, Signal, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { TokenService } from 'src/app/@core/services/token.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth.service';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material/material.module';
import { ToastService } from 'src/app/@core/services/toast/toast.service';
import { generateComputedModel } from 'src/app/@core/utils/utils';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, SharedModule, MaterialModule, RouterModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  loginForm: UntypedFormGroup;
  error: string = '';
  public version: string = '1.2.3-dev.20230904';

  private readonly authService:AuthService = inject(AuthService);
  private readonly toastService:ToastService = inject(ToastService);

  model:Signal<{email: '', password: ''}>;

  constructor(private router:Router, private route:ActivatedRoute) {

    this.version = environment.version;

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [CustomValidators.required,CustomValidators.email]),
      password: new UntypedFormControl(null, CustomValidators.required),
    });

    this.loginForm.valueChanges.subscribe((changes) => {
      this.error = null;
    });

    this.model = generateComputedModel({email: '', password: ''}, this.loginForm)

  }

  onSubmit(): void {

    this.authService.login(this.model()).subscribe({
      next: user => {
        this.router.navigate(['']);
      },
      error: error =>  {
        console.log(error)
        this.toastService.showError(error.message)
      }
    });

  }


}
