import { CdkAccordion, CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, effect, input } from '@angular/core';
import { accordionAnimation2 } from 'src/app/map/features/data-catalogue/utils/animations/accordion.animation';
import { VectorLayerComponent } from '../../vector-layer/vector-layer.component';
import BaseLayer from 'ol/layer/Base';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { LayerOnSignature } from 'ol/layer/Layer';
import LayerGroup from 'ol/layer/Group';
import { Map } from 'ol';
import { state } from '../../../data-access/services/layers.service';
import { ImageLayerComponent } from '../../image-layer/image-layer.component';
import { layersControl } from '../../../utils/layers.control';

@Component({
  selector: 'app-layer-group',
  templateUrl: './layer-group.component.html',
  styleUrls: ['./layer-group.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CdkAccordionModule,
    VectorLayerComponent,
    CdkDropList,
    CdkDrag,
    ImageLayerComponent,
  ],
  animations: [accordionAnimation2],
})
export class LayerGroupComponent extends layersControl implements OnInit {
  @ViewChild(CdkAccordion) accordion: CdkAccordion;

  layerGroup = input<state>();
  map = input.required<Map>();

  hasParent = input<boolean>(false);
  parentAccordion = input<CdkAccordion>();
  parentExpanded = input<boolean>();

  private parentStateChanged = effect(() => {
    if (this.parentExpanded()) {
      return;
    }

    if (this.parentAccordion() == null) {
      return;
    }

    this.parentAccordion().closeAll();
  });

  ngOnInit() {}

  drop(event: any) {
    var layer = event.item.data as BaseLayer;
    var layerGroup = event.container.data as LayerGroup;

    const originalIndex =
      layerGroup.getLayers().getArray().length - 1 - event.previousIndex;

    const reversedIndex =
      layerGroup.getLayers().getArray().length - 1 - event.currentIndex;

    moveItemInArray(this.layerGroup().children, originalIndex, reversedIndex);

    moveItemInArray(
      layerGroup.getLayers().getArray(),
      originalIndex,
      reversedIndex
    );

    this.map().render();
  }
}
