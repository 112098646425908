export class CreateEvent<T> {
  public create: boolean;
  public model?: T | null;

  constructor(create: boolean, model: T | null = null) {
    this.create = create;
    this.model = model;
  }

  static create<U>(model: U): CreateEvent<U> {
    return new CreateEvent<U>(true, model);
  }

  static cancel<U>(): CreateEvent<U> {
    return new CreateEvent<U>(false, null);
  }
}
