
<div class="layer">

          <div class="flex justify-center align-center gap--xs"> <button
            LayerVisibilityControl [layer]="layer().layer" [currentVisibleState]="layer().visible"
            class="feature-layer-accordion-item-button"
          >
          @switch (layer().visible) {

            @case ('visible') {
              <i class="fa fa-eye"></i>
            }
            @case ('partial') {
              <i class="fa fa-low-vision"></i>
            }
            @case ('hidden') {
              <i class="fa fa-eye-slash"></i>
            }
            @default {
              <i class="fa fa-eye"></i>
            }

          }
          </button>

          <style-renderer #renderer [kind]="layer().kind" [style]="layer().layer.getStyle()"  [featureProperties]="layer().featureProperties"></style-renderer>
                <span>{{layer().title}}
                </span>
        </div>


          <label>Opacity:<input autocomplete="off" data-lpignore="true" #opacityInput type="range" min="0" max="1" step="0.01" layerOpacityControl [layer]="layer().layer" [value]="layer().opacity"></label>
          @if(layer().hasRemoveFn)
          {
            <button class=" button button--icon" (click)="layer().removeFn($event)"><span><i class="fa fa-trash"></i></span></button>
          }

</div>

