import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  effect,
  input,
  model,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkListbox, CdkOption } from '@angular/cdk/listbox';
import { CatalogueLayer } from '../../data-access/models/catalogue-data.model';
import { TruncateTextPipe } from 'src/app/shared/pipes/truncate-text.pipe';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'catalogue-layers',
  standalone: true,
  imports: [
    CommonModule,
    CdkListbox,
    CdkOption,
    TruncateTextPipe,
    TooltipDirective,
  ],
  templateUrl: './catalogue-layers.component.html',
  styleUrl: './catalogue-layers.component.css',
})
export class CatalogueLayersComponent implements OnInit {
  @Input() layers: CatalogueLayer[] = [];

  @Input() parentExpandedChanged: Observable<any>;

  selectedLayer = model(null);

  @Output() editLayer: EventEmitter<any> = new EventEmitter();

  constructor() {


  }
  ngOnInit(): void {
    this.parentExpandedChanged.pipe().subscribe( () => {
      this.selectedLayer.set(null);
    })
  }

  edit(event, layer: CatalogueLayer) {
    event.stopPropagation();
    this.editLayer.emit(layer);
  }
}
