import { FeatureLayersComponent } from '../features/property/features/feature-layers/features/feature-layers/feature-layers.component';
import { FeaturesComponent } from '../features/feature-layers/features/features/features.component';
import { LayersComponent } from '../features/layers/layers.component';
import { ProfileComponent } from '../features/profile/profile.component';
import { CreatePropertyComponent } from '../features/property/features/create-property/create-property.component';
import { PropertyDetailsComponent } from '../features/property/features/property-details/property-details.component';
import { SidebarData } from '../features/sidebar/data-access/sidebar.service';
import { HeaderRouteData } from '../features/sidebar/features/header/data-access/header.service';

export const MapChildRoutes: Array<{
  path: string;
  component: any;
  children?: any[];
  data?: { sidebar: SidebarData; header: HeaderRouteData };
}> = [
  {
    path: 'layers',
    component: LayersComponent,
    data: {
      sidebar: {
        route: 'layers',
        title: 'Layer Management',
        className: 'fa fa-layer-group',
        position: 3,
        active: false,
        disabled: false,
      },
      header: {
        route: 'layers',
        title: 'Layer Management',
      },
    },
  },
  {
    path: 'create-property',
    component: CreatePropertyComponent,
    data: {
      sidebar: {
        route: 'create-property',
        title: 'Create Property',
        className: 'fa fa-plus',
        position: 1,
        active: false,
        disabled: false,
      },
      header: {
        route: 'create-property',
        title: 'Create Property',
      },
    },
  },
  {
    path: 'property/:id',
    component: PropertyDetailsComponent,
    data: {
      sidebar: {
        route: 'property/:id',
        title: 'Property',
        className: 'fa fa-pencil',
        position: 2,
        active: false,
        disabled: false,
      },
      header: {
        route: 'property/:id',
        title: 'Property',
        children: [
          {
            route: 'digitize',
            title: 'Project Layers',
            backButton: true,
            backRoute: 'property/:id',
          },
        ],
      },
    },
  },
  {
    path: 'property/:id/digitize',
    component: FeatureLayersComponent,
  },
  {
    path: 'settings',
    component: ProfileComponent,
    data: {
      sidebar: {
        route: 'settings',
        title: 'Settings',
        className: 'fa fa-cog',
        active: false,
        position: 4,
        disabled: false,
      },
      header: {
        route: 'settings',
        title: 'Settings',
      },
    },
  },
];
