import { Signal, computed } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { UntypedFormGroup } from "@angular/forms";

export function generateComputedModel<T>(baseModel: T, form: UntypedFormGroup) : Signal<T> {

  const changes:Signal<any> = toSignal(form.valueChanges);

  return computed( () => {
    let _updatedModel = {...baseModel};

    if(changes() == null)
    {
      return _updatedModel;
    }


   Object.keys(_updatedModel).forEach(
    key => {
      _updatedModel[key] = changes()[key]
    }
   )

      return _updatedModel
  },{});
}
