<div class="flex flex-column gap--md">
<div class="draw-features-header">

@if (!layerSelected()) {
<span>Please select a layer to enabled drawing!</span>
}
@else {
  <span>Selected Layer : {{featureLayerService.getCurrentlySelectedLayerState()().displayName}}</span>
}

</div>
<div class="flex flex-row gap--md justify-center">
  @for (definition of drawTypeDefinitions; track definition) {
    <button [appTooltip]="definition.drawType" [attr.active]="drawType() == definition.drawType && drawInteraction != null" class="digitize-button" [disabled]="!layerSelected()"  (click)="setDrawState(definition.drawType)"><i [className]="definition.icon"></i></button>
  }
</div>
</div>
