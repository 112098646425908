import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  model,
  WritableSignal,
  signal,
  effect,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkAccordion, CdkAccordionModule } from '@angular/cdk/accordion';
import {
  CatalogueCategory,
  CatalogueLayer,
} from '../../data-access/models/catalogue-data.model';
import { CatalogueLayersComponent } from '../catalogue-layers/catalogue-layers.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { Observable } from 'rxjs';
import { accordionAnimation } from '../../utils/animations/accordion.animation';
import { AccordionState } from '../../data-access/models/accordion-state.model';

@Component({
  selector: 'catalogue-categories',
  standalone: true,
  imports: [
    CommonModule,
    CdkAccordionModule,
    CatalogueLayersComponent,
    TooltipDirective,
  ],
  templateUrl: './catalogue-categories.component.html',
  styleUrl: './catalogue-categories.component.css',
  animations: [accordionAnimation],
})
export class CatalogueCategoriesComponent implements OnChanges, AfterViewInit {
  @ViewChild(CdkAccordion) accordion: CdkAccordion;

  selectedLayer = model<CatalogueLayer>();
  selectedCategory = model<CatalogueCategory>();

  public accordionState: Map<number, WritableSignal<AccordionState>> = new Map<
    number,
    WritableSignal<{ expanded: boolean }>
  >();

  private currentlyExpandedID: number = -1;
  private _expandedCategoryID: WritableSignal<number> = signal(-1);

  @Input()
  set expandedCategoryID(value: number) {
    this._expandedCategoryID.set(value);
  }

  get expandedCategoryID(): number {
    return this._expandedCategoryID();
  }

  @Input() categories: CatalogueCategory[] = [];

  @Input() parentExpandedChanged: Observable<any>;

  @Output() addCategory = new EventEmitter();

  @Output() editCategory = new EventEmitter();

  @Output() editLayer = new EventEmitter();

  constructor() {
    effect(
      () => {
        this.updateAccordionState(this._expandedCategoryID());
      },
      { allowSignalWrites: true }
    );
  }

  ngOnChanges(changes) {
    if (this.categories != null) {
      this.categories.forEach((data) => {
        if (this.accordionState.has(data.dataCatalogueCategoryID)) {
          this.accordionState.set(
            data.dataCatalogueCategoryID,
            this.accordionState.get(data.dataCatalogueCategoryID)
          );
        } else {
          this.accordionState.set(
            data.dataCatalogueCategoryID,
            signal({ expanded: false })
          );
        }
      });
    }
  }

  ngAfterViewInit(): void {}

  edit(event, category: CatalogueCategory) {
    event.stopPropagation();

    this.editCategory.emit(category);
  }

  add(event) {
    event.stopPropagation();

    this.addCategory.emit();
  }

  categoryAccordionOpened(category: CatalogueCategory): void {
    this.updateAccordionState(category.dataCatalogueCategoryID);
    this.selectedCategory.set(category);
  }

  categoryAccordionClosed(category: CatalogueCategory): void {
    this.updateAccordionState(category.dataCatalogueCategoryID);
    this.selectedCategory.set(null);
  }

  updateAccordionState(dataCatalogueCategoryID: number): void {
    if (
      this.currentlyExpandedID != -1 &&
      this.currentlyExpandedID != dataCatalogueCategoryID
    ) {
      this.accordionState
        .get(this.currentlyExpandedID)
        .set({ expanded: false });
    }
    this.accordionState.get(dataCatalogueCategoryID)?.set({ expanded: true });
    this.currentlyExpandedID = dataCatalogueCategoryID;
  }
}
