import {
  AfterViewInit,
  Component,
  computed,
  EventEmitter,
  Inject,
  inject,
  OnInit,
  Output,
  Signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CustomValidators } from '../../../../../../../../shared/extensions/CustomValidators';
import { CreateCatalogueLayer } from '../../../data-access/models/create-update-catelogue.model';
import { CreateEvent } from '../../../../../../../../@core/events/createEvent';
import { MiniMapService } from '../../../data-access/services/mini-map.service';
import { DataCatalogueService } from '../../../../../data-access/services/data-catalogue.service';
import { MaterialModule } from '../../../../../../../../material/material.module';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import {
  CatalogueCategory,
  CatalogueGroup,
} from 'src/app/map/features/data-catalogue/data-access/models/catalogue-data.model';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { CustomDialogContainer } from '../../../../../../../../@core/components/custom-dialog-container.component';
import { ListWmsGetCapabilitiesLayersComponent } from '../list-wms-get-capabilities-layers/list-wms-get-capabilities-layers.component';
import {
  MapLayer,
  WFSCapability,
  WMSCapability,
} from 'src/app/map/features/data-catalogue/data-access/models/getcapabilities.model';
import { ListWfsGetCapabilitiesLayersComponent } from '../list-wfs-get-capabilities-layers/list-wfs-get-capabilities-layers.component';
import { ListArcGisMapLayersComponent } from '../list-arc-gis-map-layers/list-arc-gis-map-layers.component';
import { ListArcGisFeatureLayersComponent } from '../list-arc-gis-feature-layers/list-arc-gis-feature-layers.component';

@Component({
  selector: 'app-create-catalogue-layer-dialog-dialog',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
  templateUrl: './create-catalogue-layer-dialog.component.html',
  styleUrl: './create-catalogue-layer-dialog.component.css',
})
export class CreateCatalogueLayerDialogComponent
  implements OnInit, AfterViewInit
{
  @Output() createEvent: EventEmitter<any> = new EventEmitter();
  //@ViewChild('miniMap', { static: true }) miniMap!: ElementRef;

  readonly catalogueService: DataCatalogueService =
    inject(DataCatalogueService);

  catalogueGroupIDForm: FormControl = new FormControl<number>(undefined);

  form: FormGroup;
  categoryObservable;
  layerSources$;

  layerSources: Signal<any[]>;
  layerTypes$ = this.catalogueService.loadCatalogueLayerTypes();

  private readonly mapService: MiniMapService = inject(MiniMapService);
  private loadCategoryTrigger$: BehaviorSubject<void> =
    new BehaviorSubject<void>(undefined);
  private loadGroupTrigger$: BehaviorSubject<void> = new BehaviorSubject<void>(
    undefined
  );

  groupObservable = this.loadGroupTrigger$.pipe(
    switchMap(() => this.catalogueService.loadCatalogueGroups())
  );

  private requiredParamsArray = [];
  private requiredParamsMap = new Map<string, FormGroup>();
  private readonly dialog: Dialog = inject(Dialog);

  ngOnInit(): void {
    //this.mapService.setTarget(this.miniMap.nativeElement);
  }
  // this is a little messy, will have to refactor at some point.
  ngAfterViewInit(): void {
    let selectedGroupID = this.data.group?.dataCatalogueGroupID ?? null;

    if (selectedGroupID) {
      this.catalogueGroupIDForm.setValue(selectedGroupID);
    }
  }

  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA)
    protected data: { category: CatalogueCategory; group: CatalogueGroup }
  ) {
    this.categoryObservable = combineLatest([
      this.loadCategoryTrigger$,
      this.catalogueGroupIDForm.valueChanges.pipe(
        startWith(this.catalogueGroupIDForm.value)
      ),
    ]).pipe(
      filter(([load, groupID]) => groupID != null),
      switchMap(([load, groupID]) =>
        this.catalogueService
          .loadCatalogueCategories()
          .pipe(
            map((data) =>
              data.filter(
                (category) => category.dataCatalogueGroupID == groupID
              )
            )
          )
      )
    );

    let selectedCategoryID = this.data.category?.dataCatalogueCategoryID ?? -1;

    this.form = new FormGroup({
      name: new FormControl('', [CustomValidators.required]),
      description: new FormControl('', []),
      url: new FormControl('', [
        CustomValidators.required,
        CustomValidators.pattern(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%.\-_\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
        ),
      ]),
      _url: new FormControl('', []),
      layerUrl: new FormControl({ value: '', disabled: true }, []),
      outputFormat: new FormControl('', []),
      attribution: new FormControl('', [CustomValidators.required]),
      layerTypeID: new FormControl(-1, [CustomValidators.required]),
      sourceTypeID: new FormControl(-1, [CustomValidators.required]),
      dataCatalogueCategoryID: new FormControl(selectedCategoryID, [
        CustomValidators.required,
      ]),
      params: new FormArray([]),
    });

    const sourceTypeChanges = toSignal(
      this.form.get('sourceTypeID').valueChanges
    );

    const selectedSourceType = computed(() =>
      this.layerSources().find((src) => src.id == sourceTypeChanges())
    );

    const requiredParams = computed(() => selectedSourceType()?.requiredParams);

    toObservable(requiredParams)
      .pipe(
        tap((data) => {
          this.requiredParamsArray.forEach((param) => {
            let index = this.params.controls.indexOf(param);
            this.params.removeAt(index);
          });
          this.requiredParamsArray = [];
          this.requiredParamsMap.clear();
        }),
        delay(20),
        takeUntilDestroyed()
      )
      .subscribe((requiredParams) => {
        if (typeof requiredParams === 'undefined' || requiredParams === null) {
          return;
        }

        Object.keys(requiredParams).forEach((key) => {
          let param = this.addParam(key, requiredParams[key], true);
          this.requiredParamsMap.set(key, param);
          this.requiredParamsArray.push(param);
        });
      });

    this.layerSources$ = this.form.get('layerTypeID').valueChanges.pipe(
      startWith(null),
      filter((val) => val != null && val > -1),
      switchMap((selectedType) => {
        return this.catalogueService
          .loadCatalogueLayerSources()
          .pipe(map((data) => ({ selectedType, data })));
      }),
      map(({ selectedType, data }) =>
        data.filter((data) => data.layerTypeID == selectedType)
      )
    );

    this.layerSources = toSignal(this.layerSources$, { initialValue: [] });
  }

  get showCapabilitiesLayerBtn(): boolean {
    return (
      this.form.get('sourceTypeID').value === 3 ||
      this.form.get('sourceTypeID').value === 4 ||
      this.form.get('sourceTypeID').value === 5 ||
      this.form.get('sourceTypeID').value === 6
    );
  }

  createNewCategory() {
    this.catalogueService.createNewCategory(
      this.form,
      this.dialog,
      this.loadCategoryTrigger$
    );
  }

  submit() {
    let model: CreateCatalogueLayer = {
      Name: this.form.get('name').value,
      Description: this.form.get('description').value,
      Url: this.form.get('_url').value,
      Attribution: this.form.get('attribution').value,
      LayerTypeID: this.form.get('layerTypeID').value,
      SourceTypeID: this.form.get('sourceTypeID').value,
      DataCatalogueCategoryID: this.form.get('dataCatalogueCategoryID').value,
    };

    model['params'] = this.params.controls.reduce((acc, formGroup) => {
      const name = formGroup.get('name').value;
      acc[name] = formGroup.get('value').value.toString();
      return acc;
    }, {});

    this.createEvent.emit(CreateEvent.create<CreateCatalogueLayer>(model));
    this.dialogRef.close();
  }

  cancel(): void {
    this.createEvent.emit(CreateEvent.cancel<CreateCatalogueLayer>());
    this.dialogRef.close();
  }

  validate(): void {
    this.mapService.addAndCheckNewLayer(
      this.form.get('url').value,
      this.form.get('sourceTypeID').value,
      this.form.get('name').value
    );
  }

  getCapabilitiesOrLayers(): void {
    let url: string = this.form.get('_url').value;
    if (this.form.get('sourceTypeID').value === 5) {
      this._getWMSCapabilities(url);
    } else if (this.form.get('sourceTypeID').value === 3) {
      this._getWFSCapabilities(url);
    } else if (this.form.get('sourceTypeID').value === 4) {
      this._getArcGISRestFeatureLayers(url);
    } else {
      this._getArcGISRestMapLayers(url);
    }
  }

  get isGetCapabilitiesOrGetLayerBtnDisabled(): boolean {
    return this.form.get('url').value === '' || this.form.get('url').invalid;
  }

  get params() {
    return this.form.get('params') as FormArray;
  }

  addParam(name = null, value = null, nameDisabled = false) {
    const attributeFormGroup = new FormGroup({
      name: new FormControl({ value: name, disabled: nameDisabled }, [
        CustomValidators.required,
      ]),
      value: new FormControl({ value: value, disabled: false }, [
        CustomValidators.required,
      ]),
    });
    this.params.push(attributeFormGroup);

    return attributeFormGroup;
  }

  removeParam(index: number) {
    this.params.removeAt(index);
  }

  onFocusOut(): void {
    const url = this.form.get('url')!.value.split('?')[0];
    this.form.get('_url')!.setValue(url);
  }

  _getWMSCapabilities(url: string) {
    if (!url.includes('?')) {
      url = `${url}?service=wms&request=GetCapabilities`;
    } else {
      if (!url.includes('request=GetCapabilities')) {
        url = `${url}&request=GetCapabilities`;
      }
      if (!url.includes('&service=wms')) {
        url = `${url}&service=wms`;
      }
    }

    let instance = this.dialog.open(ListWmsGetCapabilitiesLayersComponent, {
      container: CustomDialogContainer,
      data: { url: encodeURIComponent(url) },
      disableClose: true,
      minHeight: '50vh',
      maxHeight: '50vh',
    }).componentInstance;

    instance.selectionEvent.subscribe((event: CreateEvent<WMSCapability>) => {
      if (event.create) {
        const paramGroup = this.requiredParamsMap.get('LAYERS');
        paramGroup.get('value').setValue(event.model.name);
      }
    });
  }

  _getWFSCapabilities(url: string) {
    if (!url.includes('?')) {
      url = `${url}?service=wfs&request=GetCapabilities`;
    } else {
      if (!url.includes('request=GetCapabilities')) {
        url = `${url}&request=GetCapabilities`;
      }
      if (!url.includes('&service=wfs')) {
        url = `${url}&service=wfs`;
      }
    }

    let instance = this.dialog.open(ListWfsGetCapabilitiesLayersComponent, {
      container: CustomDialogContainer,
      data: { url: encodeURIComponent(url) },
      disableClose: true,
      minHeight: '50vh',
      maxHeight: '50vh',
    }).componentInstance;

    instance.selectionEvent.subscribe((event: CreateEvent<WFSCapability>) => {
      if (event.create) {
        const paramGroup = this.requiredParamsMap.get('LAYERS');
        paramGroup.get('value').setValue(event.model.name);
      }
    });
  }

  _getArcGISRestMapLayers(url: string) {
    if (!url.includes('MapServer')) {
      url = `${url}/MapServer`;
    }
    if (!url.includes('?')) {
      url = `${url}?f=json`;
    }

    let instance = this.dialog.open(ListArcGisMapLayersComponent, {
      container: CustomDialogContainer,
      data: { url: encodeURIComponent(url) },
      disableClose: true,
      minHeight: '50vh',
      maxHeight: '50vh',
    }).componentInstance;

    instance.selectionEvent.subscribe((event: CreateEvent<MapLayer>) => {
      if (event.create) {
        const paramGroup = this.requiredParamsMap.get('LAYERS');
        const value: string = `${paramGroup.get('value').value}${
          event.model.id
        }`;
        paramGroup.get('value').setValue(value);
      }
    });
  }

  _getArcGISRestFeatureLayers(url: string) {
    if (!url.includes('FeatureServer')) {
      url = `${url}/FeatureServer`;
    }
    if (!url.includes('?')) {
      url = `${url}?f=json`;
    }

    let instance = this.dialog.open(ListArcGisFeatureLayersComponent, {
      container: CustomDialogContainer,
      data: { url: encodeURIComponent(url) },
      disableClose: true,
      minHeight: '50vh',
      maxHeight: '50vh',
    }).componentInstance;

    instance.selectionEvent.subscribe((event: CreateEvent<MapLayer>) => {
      if (event.create) {
        const paramGroup = this.requiredParamsMap.get('LAYERS');
        paramGroup.get('value').setValue(event.model.id);
      }
    });
  }
}
