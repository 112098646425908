import { Component, EventEmitter, Output, effect, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { Kind, StyleRendererComponent } from '../style-renderer.component';
import { LineEditorComponent } from '../line-editor/line-editor.component';
import { FillEditorComponent } from '../fill-editor/fill-editor.component';
import { PointEditorComponent } from '../point-editor/point-editor.component';
import OlParser from 'geostyler-openlayers-parser';
import { Style as geoStyle } from 'geostyler-style'

import { Style } from 'ol/style';
import { accordionAnimation } from 'src/app/map/features/data-catalogue/utils/animations/accordion.animation';


type StateType = {
  [key in Kind]: Style | null;
};



@Component({
  selector: 'app-style-editor',
  standalone: true,
  imports: [CommonModule, CdkAccordionModule, StyleRendererComponent, LineEditorComponent,FillEditorComponent,PointEditorComponent],
  templateUrl: './style-editor.component.html',
  styleUrl: './style-editor.component.css',
  animations:[accordionAnimation]
})
export class StyleEditorComponent {

  @Output() styleChanged = new EventEmitter();

  items:Kind[] = ['Polygon','LineString','Point']



  private readonly olParser = new OlParser();

  state = signal<StateType>({'Point': null, 'Polygon': null, 'LineString': null, 'MultiPolygon': null, 'All':null})

  constructor()
  {

    effect(async ()=> {

      let state = this.state();
      let styles = [];

      Object.keys(state).forEach(key => {
        if(state[key] == null)
        {
         return;
        }
        styles.push(state[key])
      });

      if(styles.length == 0)
      {
        return;
      }

      let reading = await this.olParser.readStyle(styles)

      this.styleChanged.emit(JSON.stringify(reading.output))
    })
  }

  updateStyle(kind: Kind, value: Style, renderer?)
  {
    console.log(renderer)
      this.state.set({...this.state(), [kind]:value})
  }
}
