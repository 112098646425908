import { Component, inject, signal, WritableSignal } from '@angular/core';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { DataCatalogueService } from '../data-catalogue/data-access/services/data-catalogue.service';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from 'src/app/shared/accordion/accordion.component';
import { AccordionItemComponent } from 'src/app/shared/accordion-item/accordion-item.component';
import { MapService } from '../../data-access/map.service';
import LayerGroup from 'ol/layer/Group';
import BaseLayer from 'ol/layer/Base';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { accordionAnimation, accordionAnimation2 } from '../data-catalogue/utils/animations/accordion.animation';
import { LayerGroupComponent } from './ui/layer-group/layer-group/layer-group.component';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { Type } from 'ol/geom/Geometry';
import { LayersService } from './data-access/services/layers.service';
import TileLayer from 'ol/layer/Tile';
import { TileWMS } from 'ol/source';
import { VectorLayerComponent } from './ui/vector-layer/vector-layer.component';
import { ImageLayerComponent } from './ui/image-layer/image-layer.component';
import { CdkDrag, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';

export interface LayerDTO {
  layerID: number;
  name: string;
  style?: string;
  features: UserFeatureDTO[];
}

export interface UserFeatureDTO {
  featureID: number;
  name: string;
  geom: string;
  attributes: FeatureAttributes[];
}

export interface FeatureAttributes {
  [key: string]: any;
}




@Component({
  selector: 'app-layers',
  templateUrl: './layers.component.html',
  styleUrls: ['./layers.component.css'],
  standalone:true,
  imports:[CommonModule, AccordionComponent, CdkAccordionModule, LayerGroupComponent, VectorLayerComponent, ImageLayerComponent, CdkDropList, CdkDrag],
  animations:[accordionAnimation2]
})
export class LayersComponent {

  private readonly modalService: ModalService = inject(ModalService);
  public readonly mapService: MapService = inject(MapService);
  public readonly layersService: LayersService = inject(LayersService);

  count = 100;

  readonly catalogueService: DataCatalogueService =
    inject(DataCatalogueService);

    constructor() {

      
      console.log("Test layer component init")

    }




  showDataCatalogue() {
    this.modalService.showDataCatalogue();
  }

  drop(event: any) {


    const originalIndex = this.layersService.test.length  - 1 - event.previousIndex;

    const reversedIndex = this.layersService.test.length  - 1 - event.currentIndex;

    moveItemInArray(this.layersService.test, originalIndex, reversedIndex);
    console.log(this.mapService.getMap().getLayers().getArray())

    moveItemInArray(this.mapService.getMap().getLayers().getArray(), originalIndex + 1, reversedIndex + 1);


    this.mapService.getMap().render();

  }




      addRandomLayer() {
        this.mapService.getMap().addLayer(new VectorLayer({properties:{'title': 'test'}, source: new VectorSource()}))
        }

        addTiledLayer()
        {


          let layer = new TileLayer({
            source: new TileWMS({
              url: 'https://www.environment.gov.au/mapping/services/abares/CLUM_50m/MapServer/WMSServer',
              params: {'LAYERS': 'Catchment Scale Land Use 2020 - Agricultural industries', 'TILED': true},
              transition: 0,
            }),
          properties: {title: 'Catchment Scale Land Use 2020 - Agricultural industries'}} );

          layer.set('legendURL', 'https://www.environment.gov.au/mapping/services/abares/CLUM_50m/MapServer/WMSServer?REQUEST=GetLegendGraphic&VERSION=1.3.0&FORMAT=image/png&LAYER=Catchment Scale Land Use 2020 - Agricultural industries')

          this.mapService.getMap().addLayer(layer);
        }

}
