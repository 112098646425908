

    <cdk-accordion #accordion="cdkAccordion" class="dc-accordion">
      @for(group of catalogueData; track group) {
        <cdk-accordion-item
          #accordionItem="cdkAccordionItem"
          [id]="'catalogue-group-accordion-item-' + group.dataCatalogueGroupID"
          class="dc-accordion-item"
          role="button"
          (opened)="groupAccordionOpened(group)"
          (closed)="groupAccordionClosed(group)"
          [expanded]="accordionState.get(group.dataCatalogueGroupID)().expanded"
          [ngClass]="{'collapsed': !accordionItem.expanded}"
          >

          <div class="dc-accordion-item-header" (click)="accordionItem.toggle()" [ngClass]="{'sticky-header': accordionItem.expanded}">
            {{ group.name }}
            <span class="dc-accordion-item-description">
              <button (click)="edit($event, group)" class="button button--action button--icon" appTooltip="Edit Group"
              >
                <i class="fa fa-pencil-alt"></i>
          </button>
              @if (accordionItem.expanded) {
                <i class="fa-solid fa-chevron-up"></i>
              }
              @else {
                <i class="fa-solid fa-chevron-down"></i>
              }
            </span>
          </div>
          <div
            class="dc-accordion-item-body"
            role="region"
            [@slideInOut]="accordionItem.expanded ? 'in' : 'out'">

            <catalogue-categories (addCategory)="addCategory.emit(group.dataCatalogueGroupID)"
                                  (editCategory)="editCategory.emit($event)"
                                  [parentExpandedChanged]="accordionItem.closed"
                                  [expandedCategoryID]="expandedCategoryID"
                                  [(selectedCategory)]="selectedCategory"
                                  [(selectedLayer)]="selectedLayer"
                                  (editLayer)="editLayer.emit($event)"
                                  [categories]="group.categories"></catalogue-categories>
          </div>
        </cdk-accordion-item>
      }
    </cdk-accordion>
    <button (click)="add()" class="button button--action button--muted" appTooltip="Add New Group">
      <i class="fa fa-plus"></i></button>
