import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Signal, computed, effect, inject } from '@angular/core';

import appRegex from 'src/app/shared/helpers/regex';

import {
  BehaviorSubject,
  Subject,
  catchError,
  combineLatest,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Collection, Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { Modify } from 'ol/interaction';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { Circle } from 'ol/style';

import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import VectorImageLayer from 'ol/layer/VectorImage';
import { environment } from 'src/environments/environment';
import {
  BaseEndpointService,
  IEndpoint,
} from 'src/app/@core/interfaces/IEndpoint';
import { extend } from 'ol/extent';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SelectPropertyOverlayComponent } from '../ui/select-property-overlay/select-property-overlay.component';
import { MapOverlayService } from 'src/app/map/data-access/map-overlay.service';
import { MapService } from 'src/app/map/data-access/map.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  boundaryStyle,
  getAreaStyle,
  getPaddockStyle,
} from '../utils/map-syles/property.style';
import {
  ConvertFeatureToWKT,
  CovertWKTFeature,
} from 'src/app/shared/helpers/transformations';
import LayerGroup from 'ol/layer/Group';
import BaseLayer from 'ol/layer/Base';
import { PropertyClassService } from 'src/app/@core/property-class/data-access/services/property-class.service';
import { AuthService } from 'src/app/@core/Auth/auth.service';
import { ToastService } from 'src/app/@core/services/toast/toast.service';

@Injectable({ providedIn: 'root' })
export class PropertiesService
  extends BaseEndpointService
  implements IEndpoint
{
  private readonly modalService: ModalService = inject(ModalService);
  private readonly propertyClassService: PropertyClassService =
    inject(PropertyClassService);
  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly mapOverlay: MapOverlayService = inject(MapOverlayService);
  private readonly authService = inject(AuthService);
  private readonly toastService = inject(ToastService);

  public featureMap = new Map<number, Feature<Geometry>>();
  public testMap = new Map<number, BehaviorSubject<boolean>>();
  public properties: Signal<any[]>;

  public readonly _properties: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private readonly propertyMap = new Map<string, any>();
  private readonly propertyClassLayerSourceMap = new Map<
    number,
    VectorSource<any>
  >();

  private readonly propertyBoundariesLayer = new VectorImageLayer({
    source: new VectorSource(),
  });

  private readonly propertyAreaLayer = new VectorImageLayer({
    source: new VectorSource(),
    style: (feature, resolution) => {
      return getAreaStyle(feature, resolution, null);
    },
  });

  private readonly lagunaBayManagedBoundaries = new VectorImageLayer({
    source: new VectorSource(),
    style: new Style({
      stroke: new Stroke({ color: 'rgba(255,0,255,1)' }),
      fill: new Fill({ color: 'rgba(255,255,255,0.25)' }),
    }),
  });

  private propertyClasses: LayerGroup = new LayerGroup({
    properties: { title: 'Property Classes' },
  });
  public propertyGroup: LayerGroup = new LayerGroup({
    layers: [
      this.propertyBoundariesLayer,
      this.lagunaBayManagedBoundaries,
      this.propertyAreaLayer,
      this.propertyClasses,
    ],
    properties: { title: 'Property' },
  });

  public activeProperty: BehaviorSubject<any> = new BehaviorSubject({});

  public clearEvent = new Subject<void>();

  public propertyLoadedEvent = new Subject<{
    id: string;
    feature: Feature<any>;
  }>();

  constructor() {
    super({ route: 'properties' });

    this.properties = toSignal(this._properties, { initialValue: [] });

    this.propertyBoundariesLayer.set('title', 'Other Boundaries');
    this.lagunaBayManagedBoundaries.set(
      'title',
      'Laguna Bay Managed Boundaries'
    );

    this.propertyAreaLayer.set('title', 'Area (Ha)');

    this.authService.isLoggedIn$.pipe().subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.getAll().subscribe();
      } else {
        this.clearAllData();
        this._properties.next([]);
      }
    });
  }

  loadData() {
    combineLatest([
      this.propertyClassService.getAll(),
      this._properties,
    ]).subscribe(([propertyClasses, properties]) => {
      this.clearAllData();

      propertyClasses.forEach((propertyClass) => {
        let { vl, vs } = this.createPropertyClassLayer(propertyClass);
        this.propertyClassLayerSourceMap.set(propertyClass.propertyClassID, vs);

        this.propertyClasses.getLayers().push(vl);
      });

      properties.forEach((element) => {
        this.loadPropertyClassFeature(element);
        this.loadPropertyAreaLayer(element);
        this.loadPropertyBoundaryFeature(element);
      });
    });
  }

  private clearAllData() {
    // this.mapService.featureMap.clear();

    this.clearEvent.next();

    this.propertyBoundariesLayer.getSource().clear();
    this.propertyClasses.getLayers().clear();
    this.propertyClassLayerSourceMap.clear();
    this.propertyAreaLayer.getSource().clear();
  }

  loadPropertyClassFeature(property) {
    if (property.primaryClassID == null) {
      return;
    }

    let propertyClassSource = this.propertyClassLayerSourceMap.get(
      property.primaryClassID
    );
    let centroid = CovertWKTFeature(property.centroidGeom);

    centroid.set('primaryClassID', property.primaryClassID);
    centroid.set('id', property.propertyID);
    propertyClassSource.addFeature(centroid);
  }

  loadPropertyBoundaryFeature(property) {
    let feature = CovertWKTFeature(property.geom);
    this.propertyMap.set(property.propertyID, property);
    feature.set('id', property.propertyID);

    if (
      property.stage != null &&
      property.stage.toUpperCase() == 'LAGUNA BAY MANAGED'
    ) {
      this.lagunaBayManagedBoundaries.getSource().addFeature(feature);
    } else {
      this.propertyBoundariesLayer.getSource().addFeature(feature);
    }

    // this.mapService.featureMap.set(property.propertyID, feature);

    this.propertyLoadedEvent.next({
      id: property.propertyID,
      feature: feature,
    });
  }

  loadPropertyAreaLayer(property) {
    let feature = CovertWKTFeature(property.geom);
    feature.set('id', property.propertyID);
    feature.set('area', property.areaHaCalculated);

    this.propertyAreaLayer.getSource().addFeature(feature);
  }

  showPropertyDetails(propertyID) {
    this.router.navigate([`/map/property`, propertyID]);
  }

  editProperty(property) {
    return this.modalService.showPropertyEditDialog({
      model: property,
      uploadUrl: this.getFileUploadURL(property.propertyID),
    });
  }

  get(propertyID: string) {
    return this.http
      .get(`${this.endpoint}/${propertyID}`)
      .pipe(tap((property) => this.activeProperty.next(property)));
  }
  delete(propertyID: any) {
    return this.http
      .delete(`${this.endpoint}/${propertyID}`)
      .pipe(tap(() => this.getAll().subscribe()));
  }

  update(propertyID, model) {
    return this.http.patch(`${this.endpoint}/${propertyID}`, model).pipe(
      tap(() => {
        this.getAll().subscribe();

        this.toastService.showSuccess('Property Updated');
      }),
      catchError((error) => {
        this.toastService.showError('Error Updating Property');
        return error;
      })
    );
  }

  create(feature) {
    let wkt = ConvertFeatureToWKT(feature);

    return this.http.post<string>(this.endpoint, { wkt: wkt }).pipe(
      map((data) => data.split(' ')[2]),
      tap(() => this.getAll().subscribe())
    );
  }

  getAll() {
    return this.http.get<any[]>(this.endpoint).pipe(
      map((data) => {
        return data.map((item) => {
          return {
            ...item,
            listingDate: new Date(item.listingDate),
            createdAt: new Date(item.createdAt),
            updatedAt: new Date(item.updatedAt),
          };
        });
      }),
      tap((data) => this._properties.next(data))
    );
  }

  private createPropertyClassLayer(propertyClass) {
    let vs = new VectorSource();

    let vl = new VectorImageLayer({
      source: vs,
      style: (feature, resolution) => {
        return getPaddockStyle(feature, resolution, '');
      },
    });

    vl.set('title', propertyClass.class);
    vl.set('primaryClassID', propertyClass.propertyClassID);

    return { vl, vs };
  }

  getFileUploadURL(propertyID: string) {
    return `${this.endpoint}/${propertyID}/files` as const;
  }

  getFileURL(propertyID: string, safeName: string) {
    return `${this.endpoint}/${propertyID}/files/${safeName}` as const;
  }

  deleteFile(propertyID: string, file) {
    let url = this.getFileURL(propertyID, file.safeName);

    return this.modalService.showConfirmDelete('File', file.unsafeName).pipe(
      filter((result) => result.delete),
      switchMap(() => this.http.delete(url))
    );
  }

  handleDoubleClick(e, map, callback) {
    let uniqueFeaturesMap = new Map();
    e.stopPropagation();
    map.forEachFeatureAtPixel(e.pixel, (feature) => {
      let featureId = feature.get('id');
      uniqueFeaturesMap.set(featureId, feature);
    });

    let features = Array.from(uniqueFeaturesMap.values());

    if (features.length == 0) {
      if (appRegex.propertyRouteRegex.test(this.router.url)) {
        this.router.navigate(['/map/property', ':id']);
      }
      this.activeProperty.next(null);
      return;
    }

    if (features.length > 1) {
      let foundProperties = features.map((feat) =>
        this.propertyMap.get(feat.get('id'))
      );
      let propertySelectedEvent = this.mapOverlay.showPropertySelect(
        e.pixel,
        foundProperties
      );

      propertySelectedEvent.subscribe((selectedProperty) => {
        this.showPropertyDetails(selectedProperty.propertyID);
        callback(selectedProperty.propertyID);
      });
    } else {
      let selectedProperty = this.propertyMap.get(features[0].get('id'));
      this.showPropertyDetails(selectedProperty.propertyID);

      callback(selectedProperty.propertyID);
    }

    e.stopPropagation();
  }

  generateCSVExport(filters, sortBy) {
    return this.http.post(
      `${this.endpoint}/export/csv`,
      { filters: filters, sortBy: sortBy },
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }
}
