

  <cdk-accordion [multi]="false" #cdkAccordion="cdkAccordion" class="dc-accordion">
    @for (category of categories; track $index) {
      <cdk-accordion-item
      #accordionItem="cdkAccordionItem"
      class="dc-accordion-item"
      role="button"
      [expanded]="accordionState.get(category.dataCatalogueCategoryID)().expanded"
      (opened)="categoryAccordionOpened(category)"
      (closed)="categoryAccordionClosed(category)"
      >

      <div class="dc-accordion-item-header" (click)="accordionItem.toggle()" [ngClass]="{'sticky-header': accordionItem.expanded}">
        {{ category.name }}
        <span class="dc-accordion-item-description">
          <button (click)="edit($event, category)" class="button button--action button--icon" appTooltip="Edit Category"
    >
      <i class="fa fa-pencil-alt"></i>
        </button>

          @if (accordionItem.expanded) {
            <i class="fa-solid fa-chevron-up"></i>
          }
          @else {
            <i class="fa-solid fa-chevron-down"></i>
          }

        </span>
      </div>
      <div
        class="dc-accordion-item-body"
        role="region"
        [@slideInOut]="accordionItem.expanded ? 'in' : 'out'">
          <catalogue-layers [(selectedLayer)]="selectedLayer"
                            [parentExpandedChanged]="accordionItem.closed"
                            [layers]="category.layers"
                            (editLayer)="editLayer.emit($event)"></catalogue-layers>

      </div>
    </cdk-accordion-item>
    }
    </cdk-accordion>
    <div class="">
      <button (click)="add($event)" class="button button--action button--muted" matTooltip="Add new Category" appTooltip="Add new Category"
>
<i class="fa fa-plus"></i>
</button>

    </div>
