import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, Signal, ViewChild, ViewContainerRef, computed, effect, inject, signal} from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputTextModule} from 'primeng/inputtext'
import { Table, TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleButtonModule } from 'primeng/togglebutton';

import {toSignal} from '@angular/core/rxjs-interop'

import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { delay, filter, map, of, switchMap } from 'rxjs';

import { PropertiesTableService } from './data-access/properties-table.service';
import { Dialog } from '@angular/cdk/dialog';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { MapService } from 'src/app/map/data-access/map.service';
import { SharedModule } from 'primeng/api';
import { DisplayDatePipe } from 'src/app/shared/pipes/display-date.pipe';
import { SidebarService } from '../../../sidebar/data-access/sidebar.service';
import { PropertiesService } from '../../data-access/properties.service';
import { DraggableDirective } from 'src/app/shared/directives/draggable/draggable.directive';
import { Router } from '@angular/router';
import { PropertyClassService } from 'src/app/@core/property-class/data-access/services/property-class.service';
import { PropertyStageService } from 'src/app/@core/property-stage/data-access/services/property-stage.service';
import { PropertyStatusService } from 'src/app/@core/property-status/data-access/services/property-status.service';
import { PropertySuitabilityService } from 'src/app/@core/property-suitability/data-access/services/property-suitability.service';

import { MultiSelectModule } from 'primeng/multiselect';
@Component({
  selector: 'app-properties-table',
  standalone: true,
  imports: [CommonModule,
    ButtonModule,
    TableModule,
     ReactiveFormsModule,
      FormsModule,
      ToggleButtonModule,
       SharedModule,
        DisplayDatePipe ,
        CalendarModule,
        InputTextModule,
        DraggableDirective,
        MultiSelectModule
      ],
  templateUrl: './properties-table.component.html',
  styleUrl: './properties-table.component.css'
})
export class PropertiesTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('draggableContainer', {static: true}) draggableContainer: ElementRef<HTMLElement>;

  @ViewChild('table', {static: true}) table: Table;

  public readonly propertiesService:PropertiesService = inject(PropertiesService);
  public readonly state: PropertiesTableService = inject(PropertiesTableService)
  private readonly sidebarService: SidebarService = inject(SidebarService);
  private readonly mapService: MapService = inject(MapService);
  private readonly renderer: Renderer2 = inject(Renderer2);
  private bottomSheetRef: MatBottomSheetRef<PropertiesTableComponent> = inject(MatBottomSheetRef<PropertiesTableComponent>)
  private viewContainerRef: ViewContainerRef = inject(ViewContainerRef)
  private readonly modalService: ModalService = inject(ModalService);
  private readonly router: Router = inject(Router);
  private readonly propertyClassService:PropertyClassService = inject(PropertyClassService);
  private readonly propertyStageService:PropertyStageService = inject(PropertyStageService);
  private readonly propertySuitabilityService:PropertySuitabilityService = inject(PropertySuitabilityService);
  private readonly propertyStatusService:PropertyStatusService = inject(PropertyStatusService);


  balanceFrozen: boolean = false;
  sidebarOpening:Signal<boolean>;
  filterApplied:Signal<boolean>;
  scrollContainer: HTMLElement;

  classes = toSignal(this.propertyClassService.getAll().pipe(map(classes => classes.map(cl => cl.class))));
  statuses = toSignal(this.propertyStatusService.getAll().pipe(map(status => status.map(status => status.status))));
  stages = toSignal(this.propertyStageService.getAll().pipe(map(stages => stages.map(stage => stage.name))));
  suitabilities = toSignal(this.propertySuitabilityService.getAll().pipe(map(suitabilities => suitabilities.map(suitability => suitability.name))));

  tableHeight;

  constructor(private el: ElementRef) {


    this.sidebarOpening = toSignal(this.sidebarService.drawer.openedStart.pipe(switchMap(() => of(true))),{initialValue: false});



    effect(() => {

      if(this.sidebarOpening())
      this.bottomSheetRef.dismiss()
    });

    effect(()=>
    {

      if(!this.state.selectedRow())
      {
        return;
      }

      this.router.navigate([`/map/property`, this.state.selectedRow().propertyID]);
      this.mapService.zoomToFeature(this.state.selectedRow().propertyID,this.state.containerHeight())
      this.sidebarService.open();

    })



    this.tableHeight = computed(() => {

      return this.state.containerHeight() < 250 ? '250px' : `${this.state.containerHeight() - 70}px`;

    });




    effect(() => {
      this.renderer.setStyle(this.draggableContainer.nativeElement, 'height', `${this.state.containerHeight()}px`);
    })


  }
  ngOnInit(): void {


    this.table.onFilter.subscribe(() => {
      setTimeout(() => {
        this.state.containerHeight.set(this.state.containerHeight() + 1)
      });

    })

  if(this.state.filterMetaData)
  {

    Object.keys(this.state.filterMetaData).forEach((field) => {
      let filterValue = this.state.filterMetaData[field].value;
      let matchMode = this.state.filterMetaData[field].matchMode;
      this.table.filter(filterValue, field, matchMode);
  });
  }
  }

  openEditDialog(property)
  {
    this.propertiesService.get(property.propertyID).pipe(
      switchMap(property => this.propertiesService.editProperty(property)),
      filter(result => result.update),
      switchMap(result => this.propertiesService.update(property.propertyID,result.model)),
      ).subscribe(property =>
        {

        })
  }

  confirmDelete(property)
  {

   let entityType =  Object.keys(property)[0]

    this.modalService.showConfirmDelete(entityType,property.propertyName)
    .pipe(
    filter(result => result.delete),
    switchMap(() => this.propertiesService.delete(property.propertyID))
    ).subscribe(data => {
      console.log(data)
    })
  }

  ngAfterViewInit(): void {

    this.setupScrollPosition();
  }

  ngOnDestroy(): void {
    this.state.filterMetaData = this.table.filters

    const activeFilters = Object.keys(this.state.filterMetaData)
    .filter(key =>this.state.filterMetaData[key] && this.state.filterMetaData[key].value)
    .reduce((obj, key) => {
      obj[key] = this.state.filterMetaData[key];
      return obj;
    }, {});


    console.log(JSON.stringify(activeFilters))
  }

  private setupScrollPosition()
  {
    this.scrollContainer = document.getElementsByClassName('p-scroller')[0] as HTMLElement;

    this.scrollContainer.addEventListener('scroll', (event) =>
    {
      let target = (event.target as HTMLElement);
        this.state.scrollPosition.set(target.scrollTop)
    });

    this.scrollContainer.scrollTop = this.state.scrollPosition();
  }


  onHeightChange(newHeight: number) {

        let maxHeight = window.innerHeight * 0.8;
        let minHeight = window.innerHeight * 0.25;

        if(minHeight >= newHeight)
        {
          return;
        }
        if (newHeight > maxHeight) {
            newHeight = maxHeight;
        }

        this.state.containerHeight.set(newHeight);
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  getCurrentHeight()
  {
    return this.draggableContainer.nativeElement.offsetHeight;
  }

  log(value)
  {
    console.log(value)
  }

  onSort(event)
  {
    this.state.sortMetaData = event;
  }

  exportToCSV()
  {
    const currentfilterState = this.table.filters as any;
    const activeFilters = Object.keys(currentfilterState)
    .filter(key =>currentfilterState[key] && currentfilterState[key].value)
    .reduce((obj, key) => {

      obj[key] = currentfilterState[key];

      if(!(currentfilterState[key].value instanceof Array))
      {
        obj[key].value = [obj[key].value.toString()];
      }

      return obj;
    }, {});

    this.propertiesService.generateCSVExport(activeFilters, this.state.sortMetaData).subscribe(response => {
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = this.getFilenameFromContentDisposition(contentDisposition);
      console.log(filename)
      this.saveFile(response.body, filename );
    });

  }

  private getFilenameFromContentDisposition(contentDisposition: string | null): string {
    if (!contentDisposition) return 'defaultFilename.txt';
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    } else {
      return 'defaultFilename.txt';
    }
  }

  private saveFile(data: Blob, filename: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}
