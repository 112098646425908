<form [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Shape</mat-label>
      <mat-select formControlName="symbol">
        <mat-option value="Circle">Circle</mat-option>
        <mat-option value="Square">Square</mat-option>
        <mat-option value="Triangle">Triangle</mat-option>
        <mat-option value="Cross">Cross</mat-option>
        <mat-option value="X">X</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="example-full-width">
      <mat-label>Radius</mat-label>
      <input formControlName="radius" matInput type="number">
    </mat-form-field>

    <mat-form-field appearance="outline" class="example-full-width">
      <mat-label>Color</mat-label>
      <input matInput formControlName="colorInput" />
      <ngx-colors
        class="suffix"
        matSuffix
        ngx-colors-trigger
        colorPickerControls="only-alpha"
        formControlName="color"
      ></ngx-colors>
    </mat-form-field>

    <mat-form-field appearance="outline" class="example-full-width">
      <mat-label>Stoke Width</mat-label>
      <input formControlName="strokeWidth" matInput type="number">
    </mat-form-field>

    <mat-form-field appearance="outline" class="example-full-width">
      <mat-label>Stroke Color</mat-label>
      <input matInput formControlName="strokeColorInput" />
      <ngx-colors
        class="suffix"
        matSuffix
        ngx-colors-trigger
        colorPickerControls="only-alpha"
        formControlName="strokeColor"
      ></ngx-colors>
    </mat-form-field>


  </form>

