import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapModule } from './map/map.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { OnlineStatusModule } from 'ngx-online-status';
import { authInterceptorProviders } from './@core/interceptors/auth.interceptor';
import { PropertyStatusService } from './@core/property-status/data-access/services/property-status.service';
import { PropertyClassService } from './@core/property-class/data-access/services/property-class.service';
import { PropertySuitabilityService } from './@core/property-suitability/data-access/services/property-suitability.service';
import { PropertyStageService } from './@core/property-stage/data-access/services/property-stage.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MapModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OnlineStatusModule
  ],
  providers: [authInterceptorProviders, PropertyStatusService, PropertyClassService, PropertySuitabilityService, PropertyStageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
