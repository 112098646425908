import { BaseCatalogue } from './base-catalogue.model';

export type Catalogue = CatalogueGroup[];

export class CatalogueGroup extends BaseCatalogue {
  dataCatalogueGroupID: number;
  categories: CatalogueCategory[];
  state?: any

  constructor(
    name: string,
    dataCatalogueGroupID: number,
    categories: CatalogueCategory[]
  ) {
    super(name);
    this.dataCatalogueGroupID = dataCatalogueGroupID;
    this.categories = categories;
  }
}

export class CatalogueCategory extends BaseCatalogue {
  dataCatalogueGroupID: number;
  dataCatalogueCategoryID: number;
  description: string;
  layers: CatalogueLayer[];
  state?: any

  constructor(
    name: string,
    dataCatalogueGroupID: number,
    dataCatalogueCategoryID: number,
    description: string,
    layers: CatalogueLayer[]
  ) {
    super(name);
    this.dataCatalogueGroupID = dataCatalogueGroupID;
    this.dataCatalogueCategoryID = dataCatalogueCategoryID;
    this.description = description;
    this.layers = layers;
  }
}

export class CatalogueLayer extends BaseCatalogue {
  dataCatalogueLayerID: number;
  description: string;
  url: string;
  params: string[][];
  attribution: string;
  layerType: string;
  sourceType: string;

  layerTypeID: number;
  sourceTypeID: number;
  dataCatalogueCategoryID: number;
  added?: boolean;
  state?: any;


  constructor(
    name: string,
    dataCatalogueLayerID: number,
    description: string,
    url: string,
    params: string[][],
    attribution: string,
    layerType: string,
    sourceType: string,
    dataCatalogueCategoryID: number,
    added?: boolean
  ) {
    super(name);
    this.dataCatalogueLayerID = dataCatalogueLayerID;
    this.description = description;
    this.url = url;
    this.params = params;
    this.attribution = attribution;
    this.layerType = layerType;
    this.sourceType = sourceType;
    this.dataCatalogueCategoryID = dataCatalogueCategoryID;
    this.added = added;
  }
}
