
<div class="example-listbox-container">
  <ul cdkListbox
      [cdkListboxValue]="selectedLayer()"
      (cdkListboxValueChange)="selectedLayer.set($event.value[0])"
      aria-labelledby="example-satisfaction-label"
      class="example-listbox">
      @for (layer of layers; track layer) {
        <li
        [cdkOption]="layer"
        [cdkOptionTypeaheadLabel]="layer.name"
        class="example-option">
      <span [appTooltip]="layer.name" >{{layer.name | truncateText:50}}</span>
      <button class="button button--action button--icon" appTooltip="Edit Layer"  (click)="edit($event, layer)"
      >
        <i class="fa fa-pencil-alt"></i>
  </button>
    </li>
      }


  </ul>
</div>
