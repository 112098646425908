<div class="title"></div>

<div class="title-bar">
  <div class="title">Create Project Layer</div>
  <div class="window-controls">
      <button (click)="cancel()" ><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content">

  <form class="grid--row grid gap--md" [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput placeholder="">
      <mat-error *ngIf="form.get('name').errors?.['required']">This field is required!</mat-error>
    </mat-form-field>

    <span>Layer Styles</span>
    <app-style-editor (styleChanged)="styleChanged($event)"></app-style-editor>
  </form>

</div>
<div class="footer">
  <button [disabled]="form.invalid" type="button" class="button button--blue" (click)="submit()"><span class="fa fa-save button-icon"></span>Submit</button>
</div>
