import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import BaseEvent from 'ol/events/Event';
import { Type } from 'ol/geom/Geometry';
import BaseLayer from 'ol/layer/Base';
import BaseImageLayer from 'ol/layer/BaseImage';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import { MapService } from 'src/app/map/data-access/map.service';


export interface state {
  title: string,
  visible: 'visible' | 'hidden' | 'partial';
  opacity:number;
  kind?: Type
  featureProperties?: {};
  layer: any,
  type: 'group'| 'vector' | 'image'
  children:WritableSignal<state>[]
  legendURL?: string,
  sourceType?: string
  hasRemoveFn: boolean,
  removeFn?: (event) => {}
}


// TODO: rebuilding the entire tree every-time seems like a waste, maybe do some checking / filtering to only re-create the needed ones.

@Injectable({
  providedIn: 'root'
})
export class LayersService {
  public readonly mapService: MapService = inject(MapService);
  public mapInstance = this.mapService.getMap();
  test: WritableSignal<state>[] = [];

  layers;
constructor() {

  this.buildLayersTree();

  this.mapInstance.getLayers().on(['add','remove'], (event: any)=> {

    this.buildLayersTree();

    if(event.element)
    this.attachAddListenerToLayer(event.element);


  })

  this.mapInstance.getLayers().forEach(layer => {
    this.attachAddListenerToLayer.call(this, layer);
  });
 }

buildLayersTree()
{
  this.test = [];

  this.layers = this.mapInstance
  .getLayers()
  .getArray()
  .filter(layer => layer.get('title') != 'Background Maps' && layer.get('title') != null);



  this.layers.forEach((layer, index) => {



    if(layer instanceof LayerGroup)
    {
      let layerState = this.buildLayerGroupState(layer,null);
      this.test.push(layerState);
    }
    else
    {
      let layerState = this.buildLayerState(layer, null);
      this.test.push(layerState);
    }


  });
}


private buildLayerGroupState(layerGroup: LayerGroup,parentState)
{
  let layersArray = layerGroup.getLayers().getArray().filter(layer => layer.get('title') != null);


  let state = signal<state>({
    title:layerGroup.get('title'),
    opacity: layerGroup.getOpacity(),
    visible:layerGroup.getVisible() ? 'visible' : 'hidden',
    layer: layerGroup,
    type:'group',
    children: [],
    hasRemoveFn: layerGroup.get('removeFn') == null ? false : true,
    removeFn: layerGroup.get('removeFn') == null ? null : layerGroup.get('removeFn'),

  });

  layerGroup.on('change:opacity', (event) => {
    let opacity = layerGroup.getOpacity();
    state.set({...state(), opacity: opacity})
   });

   layerGroup.on('change:visible', (event) => {
    state.set({...state(), visible: layerGroup.getVisible() ? "visible" : 'hidden'})

    this.updateChildrenVisibilitySate(state);

    if(!parentState)
    return;

    this.updateParentVisibilityState(parentState);
   });



  layersArray.forEach((layer,index) => {

    if(layer instanceof LayerGroup)
    {
      state().children.push(this.buildLayerGroupState(layer,state));
    }
    else
    {
      let childState = this.buildLayerState(layer,state);
      state().children.push(childState)
    }

  });

  return state;
}

private buildLayerState(layer:BaseLayer, parentState:WritableSignal<state>)
{

    let state = signal<state>({
      title: layer.get('title'),
      opacity: layer.getOpacity(),
      visible: layer.getVisible() ? 'visible' : 'hidden',
      layer: layer,
      type:'image',
      featureProperties: layer.getProperties(),
      children:[],
      legendURL: layer.get('legendURL'),
      sourceType: layer.get('sourceType'),
      hasRemoveFn: layer.get('removeFn') == null ? false : true,
      removeFn: layer.get('removeFn') == null ? null : layer.get('removeFn'),
    });

    if(layer instanceof VectorLayer || layer instanceof VectorImageLayer)
    {

      state.set({...state(), type: 'vector'});

      let source = layer.getSource() as VectorSource;

      let eventKey;

      if(source.getFeatures().length > 0)
      {
        let feature = source.getFeatures()[0];
        let kind = feature.getGeometry().getType();
        let properties = feature.getProperties();
        delete(properties['geometry'])

        state.set({...state(), kind: kind, featureProperties: properties});

      }else
      {
        eventKey = source.on('addfeature', (event) => {

          let kind = event.feature.getGeometry().getType();
          let properties = event.feature.getProperties();

          delete(properties['geometry'])

          state.set({...state(), kind: kind, featureProperties: properties});


        });
      }



    }


    layer.on('change:opacity', (event) => {
    let opacity = layer.getOpacity();
    state.set({...state(), opacity: opacity})
   });

   layer.on('change:visible', (event) => {
    state.set({...state(), visible: layer.getVisible() ? "visible" : 'hidden'})

    if(parentState == null)
    {
      return;
    }

   this.updateParentVisibilityState(parentState);
   });

  return state
}



private updateParentVisibilityState(parentState:WritableSignal<state>)
  {
    let someChildrenVisible = parentState().children.some(value => value().visible === 'visible');
    let someChildrenHidden = parentState().children.some(value => value().visible === 'hidden');

    if(someChildrenHidden && someChildrenVisible && parentState().visible !== 'hidden')
    {
      parentState.set({...parentState(), visible: 'partial'});
    }
    else if(someChildrenHidden && !someChildrenVisible)
    {
      parentState.set({...parentState(), visible: 'hidden'});
      parentState().layer.setVisible(false);
    }
    else if(parentState().visible !== 'hidden')
    {
      parentState.set({...parentState(), visible: 'visible'});
    }
  }

private updateChildrenVisibilitySate(parentState:WritableSignal<state>)
  {
    if(parentState().visible === 'visible')
    {
      parentState().children.forEach(child => {
        child.set({...child(), visible: 'visible'});
        child().layer.setVisible(true)
      })
    }
  }

  private attachAddListenerToLayer(layer) {

    if (layer instanceof LayerGroup) {
      layer.getLayers().on(['add','remove'], (event: any) => {

       if(event.element)
       {
        this.attachAddListenerToLayer(event.element)
       }
        this.buildLayersTree();

      });
      layer.getLayers().forEach(subLayer => {
        this.attachAddListenerToLayer.call(this, subLayer);
      });
    }
  }


}
