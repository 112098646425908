import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { LoginComponent } from './@core/Auth/components/login/login.component';
import { AuthGuard } from './@core/Auth/guards/auth.guard';
import { ResetPasswordComponent } from './@core/Auth/components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './@core/Auth/components/forgot-password/forgot-password.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {
    path: 'reset-password' ,component:ResetPasswordComponent, pathMatch: "full",
  },  {
    path: 'forgot-password' ,component:ForgotPasswordComponent, pathMatch: "full",
  },
  {path: 'map',canActivate:[AuthGuard] ,loadChildren: () => import('./map/map.module').then(m => m.MapModule)},
  {   path: '', redirectTo: 'map', pathMatch: "full",},
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload',  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
