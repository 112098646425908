import { HttpClient } from "@angular/common/http";
import { Injectable, inject,signal,WritableSignal } from "@angular/core";
import { Collection, Feature } from "ol";
import { Geometry } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import { BehaviorSubject, tap } from "rxjs";
import VectorSource from "ol/source/Vector";
import { LayerDTO } from "../../../../layers/layers.component";
import { Draw, Modify } from "ol/interaction";
import { Style, Fill, Stroke,Circle } from "ol/style";
import { DrawEvent } from "ol/interaction/Draw";
import { ModifyEvent } from "ol/interaction/Modify";
import OlParser from 'geostyler-openlayers-parser';
import { MapService } from "../../../../../data-access/map.service";
import { BaseEndpointService } from "src/app/@core/interfaces/IEndpoint";

export type UserLayerState = {
  layer: VectorLayer<any>
  visible: boolean,
  features: [{
    feature: Feature<Geometry>
    isEditing: boolean
  }]

}

export type LayerState = {
  visible: boolean,
  isEditing: boolean,
  expanded: boolean
  selected:boolean,
  order: number,
  displayName: string
}

export type FeatureState = {
  isEditing: boolean
}

const editStyle = new Style({
  fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)' // Semi-transparent white fill
  }),
  stroke: new Stroke({
      color: '#ffcc33', // Orange stroke color
      width: 2
  }),
  image: new Circle({
      radius: 7,
      fill: new Fill({
          color: '#ffcc33' // Orange fill for circle
      })
  })
});

@Injectable(
  {
    providedIn:'root'
  }
)
export class FeatureLayersService extends BaseEndpointService {

  private readonly mapService: MapService = inject(MapService);

  public layerMap = new Map<number, VectorLayer<any>>();
  public featureMap = new Map<number, Feature<Geometry>>();
  public layerSate = new Map<number, WritableSignal<LayerState>>();
  public featureState = new Map<number, BehaviorSubject<FeatureState>>();

  private readonly userLayers = new BehaviorSubject<LayerDTO[]>([]);

  private readonly modifySource = new VectorSource();
  private readonly modifyLayer = new VectorLayer({source: this.modifySource, style: editStyle});
  private readonly modifyInteraction = new Modify({source: this.modifySource});

  private readonly modifiedGeometries = new Map<number, Feature<any>>();
  public currentlySelectedLayerID = signal(null);
  private parser = new OlParser();

  public propertyID;
  public currentlyEditingLayer = signal(false);



  constructor()
  {
    super({route: 'properties/%PROPERTYID%/layers'})
  }

  setPropertyID(propertyID)
  {
    this.setResourceID('%PROPERTYID%',propertyID);
    this.propertyID = propertyID
  }

  getCurrentlySelectedLayerState()
  {
    return this.layerSate.get(this.currentlySelectedLayerID());
  }

  private getLayersForProperty()
  {
    return this.http.get<LayerDTO[]>(this.endpoint).pipe(tap(data => {


    this.layerMap.forEach((lyr) => {
      lyr.getSource().clear();
        this.mapService.getMap().removeLayer(lyr);

    });

    this.layerMap.clear();
    this.featureMap.clear();


      data.forEach(async (layer, index) => {




        let vs = new VectorSource();
        let vl = new VectorLayer({properties:{name: layer.name, layerID: layer.layerID}, source: vs});


        if(layer.style != null && layer.style != '')
        {
          let styleDefinition = JSON.parse(layer.style);
          console.log(styleDefinition)
          let style = await this.parser.writeStyle(styleDefinition);
          vl.setStyle(style.output);
        }

        layer.features.forEach(geom => {
            let feature = this.mapService.convertWktToFeature(geom.geom)
            feature.setProperties({name: geom.name, featureID: geom.featureID, layerID: layer.layerID});
            vs.addFeature(feature);
            this.featureMap.set(geom.featureID,feature)

            if(!this.featureState.has(geom.featureID))
            {
              this.featureState.set(geom.featureID, new BehaviorSubject({isEditing: false}));
            }


        })

          this.layerMap.set(layer.layerID, vl);

          if(this.layerSate.has(layer.layerID))
          {
            let visible = this.layerSate.get(layer.layerID)().visible;
            vl.setVisible(visible);
          }
          else
          {


              this.layerSate.set(layer.layerID, signal({visible: true, isEditing: false,expanded:false, selected:false, order: index, displayName: layer.name}));




          }


          vl.on('change:visible', (e) => {

            let layerState =  this.layerSate.get(layer.layerID);
            layerState.set({ ...layerState() ,visible: e.oldValue ? false : true});
          });

          this.mapService.getMap().addLayer(vl);
      });
    }));
  }

  getKeyByValue(searchValue) {
    for (let [key, value] of this.layerSate.entries()) {
      if (value().order === searchValue) {
        return key;
      }
    }
    return undefined;
  }

  loadLayers()
  {
    this.getLayersForProperty().subscribe(layers => {
      this.userLayers.next(layers);
    })
  }

  getLayers()
  {
    return this.userLayers.asObservable();
  }

  editFeature(featureID)
  {

    if(this.modifySource.getFeatures().length > 0)
    {
      let featureID = this.modifySource.getFeatures()[0].get('featureID');

      this.cancelEdit(featureID)
    }


  let feature = this.featureMap.get(featureID);

  let modFeature = feature.clone();
  modFeature.setStyle(editStyle)

  this.modifySource.clear();
  this.modifySource.addFeature(modFeature);

    const modify = new Modify({

      source: this.modifySource,
    });

    this.mapService.getMap().addLayer(this.modifyLayer);
    this.mapService.getMap().addInteraction(modify);
    this.featureState.get(featureID).next({isEditing: true});

    modify.on('modifyend', () => {

    });


  }

  editLayer(lyrID)
  {
      if(!this.currentlyEditingLayer())
      {

      }

      let layer = this.layerMap.get(lyrID);

      let layerState =  this.layerSate.get(lyrID);
      layerState.set({ ...layerState() ,isEditing: true});

      let features = layer.getSource().getFeatures();

      let clonedFeatures = features.map(feature => feature.clone());

      this.modifySource.clear();
      this.modifySource.addFeatures(clonedFeatures);
      this.mapService.getMap().addLayer(this.modifyLayer);
      this.mapService.getMap().addInteraction(this.modifyInteraction);

      this.currentlyEditingLayer.set(true);


      this.modifyInteraction.on('modifyend', (event:ModifyEvent) => {

        let feature = event.features.pop();

        if(feature == null)
        {
          return;
        }

        this.modifiedGeometries.set(feature.get('featureID'), feature)
      });




  }

  cancelEdit(lyrID)
  {
    this.mapService.getMap().removeLayer(this.modifyLayer);
    this.mapService.getMap().removeInteraction(this.modifyInteraction);
    this.modifiedGeometries.clear();
    let layerState =  this.layerSate.get(lyrID);
    layerState.set({ ...layerState() ,isEditing: false});
    this.currentlyEditingLayer.set(false);
  }

  setLayerState(layerID,object: {visible?,expanded?,isEditing?, selected?})
  {
    let state = this.layerSate.get(layerID);

    state.set({...state(),...object});
  }

  selectLayer(layerID)
  {
    if(this.currentlySelectedLayerID() && layerID != this.currentlySelectedLayerID())
    {
      this.layerSate.get(this.currentlySelectedLayerID())().selected = false;
    }

    this.layerSate.get(layerID)().selected = true;
    this.currentlySelectedLayerID.set(layerID);
  }


  saveChanges(lyrID)
  {
    let updateModels = [];

  this.modifiedGeometries.forEach((feature, id) => {

    let wkt = this.mapService.convertFeatureToWKT(feature);

    this.featureMap.get(id).setGeometry(feature.getGeometry())


    updateModels.push({featureID: id, wkt: wkt});

  });


if(updateModels.length > 0)
{
  this.http.patch(`https://localhost:7008/api/v1/properties/layers/${lyrID}/features`,{models:updateModels}).subscribe(result => {



});
}



     this.cancelEdit(lyrID);
  }

  toggleVisibility(lyrID, event:InputEvent)
  {
    event.preventDefault();
    event.stopPropagation();

    let layer =  this.layerMap.get(lyrID);
    layer.setVisible(layer.getVisible() ? false : true)
  }

  enableDrawing(lyrID)
  {
    let drawInteraction = new Draw({
      type: 'LineString',
      source: this.layerMap.get(lyrID).getSource()
    });

    this.mapService.getMap().addInteraction(drawInteraction);

    drawInteraction.on('drawend',(event:DrawEvent) => {

      let wkt = this.mapService.convertFeatureToWKT(event.feature);

      this.http.post(`https://localhost:7008/api/v1/properties/layers/${lyrID}`, {wkt: wkt, attributes: [{name:"luke", value: "some RandomValue"}], name: "House Landmark"}).subscribe(result => this.loadLayers());
    })

  }

  createFeature( model)
  {


    return this.http.post(`${this.endpoint}/${this.currentlySelectedLayerID()}`, model).pipe(tap(result => this.loadLayers()));
  }


  createLayer( name:string, style: string)
  {
    return this.http.post(this.endpoint, {"name": name,"style": style}).pipe(tap(response => {
      this.loadLayers()
    }))
  }

  deleteLayer(layerId:number)
  {
    return this.http.delete(`${this.endpoint}/${layerId}`).pipe(tap(response => {
        this.loadLayers();
        this.layerSate.delete(layerId);
    }))
  }


  downloadGeoJson(layerID: number, srid:number)
  {
    return this.http.get(`https://localhost:7008/api/v1/properties/${this.propertyID}/layers/${layerID}/geojson?srid=${srid}`,{ responseType: 'blob', observe: 'response' })
  }

}
