import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, inject } from '@angular/core';
import Draw from 'ol/interaction/Draw';
import Overlay from 'ol/Overlay';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { unByKey } from 'ol/Observable';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import CircleStyle from 'ol/style/Circle';
import { Feature, Map } from 'ol';
import { Control } from 'ol/control';
import { Subscription } from 'rxjs';

import { LineString, Polygon, Circle as CircleGeom } from 'ol/geom';
import { getArea, getLength } from 'ol/sphere';
import { MeasureService } from './measure.service';
import { CommonModule } from '@angular/common';
import { MeasureSettingsComponent } from './measure-settings/measure-settings.component';
import proj4 from 'proj4';
import * as olProj from 'ol/proj';
import { transform } from 'ol/proj';
import { ProjectionService } from '../../data-access/projection.service';


@Component({
  selector: 'map-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.css'],
  standalone: true,
  imports:[CommonModule, MeasureSettingsComponent]
})
export class MeasureComponent implements OnInit,AfterViewInit,OnDestroy {

  @Input() map: Map;

  private readonly measureService: MeasureService = inject(MeasureService);
  private readonly projectionService: ProjectionService = inject(ProjectionService);

  active: boolean = false;
  interaction: Draw;

  control: Control;
  measureColor:string = 'rgba(255,255,255,0)'

  spatialToolSub$: Subscription = new Subscription();

  constructor(private elRef: ElementRef) {

   this.measureColor = getComputedStyle(document.documentElement).getPropertyValue('--measure-tool-primary').trim();
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.control = new Control({target: this.elRef.nativeElement.parentNode, element: this.elRef.nativeElement});
    this.map.addControl(this.control);
  }

  ngOnDestroy(): void {
    if(this.control == null)
    return;

    this.spatialToolSub$.unsubscribe();
    this.map.removeControl(this.control);
    this.deSelect();
  }

  deSelect() {

    if (!this.active) return;

    this.map.removeInteraction(this.interaction);
    this.active = false;

  }

  clear () {

    this.measureService.toolTips.forEach((element) => {
      this.map.removeOverlay(element);
    });

    const currentLayer = this.measureService.layers.pop();

    currentLayer.getSource().clear();

    this.measureService.layers.forEach((layer) => {
      this.map.removeLayer(layer);
    });

    this.measureService.toolTips = [];
    this.measureService.layers = [currentLayer];

  }

  toggleMeasure() {
    if (this.active) {
      this.deSelect();

    } else {
      this.active = !this.active;
    }

    this.measureService.setIsActive(this.active);
  }

  selectMeasureTool(selection: {measureType, measureColor: {hex: string, rgba:string} })  {

    if (this.interaction) {
      this.map.removeInteraction(this.interaction);
    }

    let layerSource = new VectorSource();

    let layer = new VectorLayer({source:layerSource});

    const styles = [
      new Style({
        image: new Circle({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
          }),
          stroke: new Stroke({
            color: [0,0,0,1],
          }),
          radius: 5,
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, .5)',
        }),
        stroke: new Stroke({
          color: this.measureColor,
          lineDash: [2, 5],
          width: 2,
        }),
      }),
    ];

    this.interaction = new Draw({
      source: layerSource,
      type: selection.measureType,
      style: styles,
    });


    this.map.addInteraction(this.interaction);
    this.map.addLayer(layer);
    this.measureService.layers.push(layer);

    this.interaction.on('drawstart', (event) => {
      const feature = event.feature as any;

      const measureTooltipElement = document.createElement('div');
      measureTooltipElement.className = 'esk-toolbar-tooltip';

      const measureTooltipOverlay = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
      });

      this.measureService.toolTips.push(measureTooltipOverlay);
      this.map.addOverlay(measureTooltipOverlay);

      feature.attributes = {
        tooltipElement: measureTooltipElement,
        tooltipOverlay: measureTooltipOverlay,
      };

      feature.getGeometry().on('change', () => {
        this.onFeatureChange(feature);
      });

      // User defined callback from constructor
    });

    this.interaction.on('drawend', (event) => {
      const feature = event.feature as any;

      unByKey(feature.attributes.onChangeListener);

      feature.attributes.tooltipElement.className = 'esk-toolbar-tooltip';
      feature.attributes.tooltipOverlay.setOffset([0, -7]);

      feature.setStyle(styles);

      this.toggleMeasure();

    });

    this.interaction.on('drawabort', (event) => {
      const feature = event.feature as any;
      this.map.removeOverlay(feature.attributes.tooltipOverlay);
    });

    this.interaction.on('error',  (event) => {

    });
  };



   onFeatureChange(feature: any) {

      // Check if feature has attributes and tooltipElement property, if not, it has no tooltip to update
      const hasTooltip = feature?.attributes?.tooltipElement;
      if(!hasTooltip) {
          return;
      }

      const projection = `EPSG:3857`;

      const geometry = feature.getGeometry();

      let tooltipText;
      let tooltipPosition;

      if(geometry instanceof Polygon) {
          tooltipText = this.formatArea(geometry, projection);
          tooltipPosition = geometry.getInteriorPoint().getCoordinates();
      }else if(geometry instanceof LineString) {
          tooltipText = this.formatLength(geometry, projection);
          tooltipPosition = geometry.getLastCoordinate();
      }
      else if( geometry instanceof CircleGeom) {
        var test = geometry as CircleGeom;
        tooltipText = this.formatRadius(geometry);
        tooltipPosition = test.getCenter();
      }

      feature.attributes.tooltipElement.innerHTML = tooltipText;
      feature.attributes.tooltipOverlay.setPosition(tooltipPosition);
  }

   formatLength(line, projection) {
      const length = getLength(line,{projection:projection});

      if(length > 100) {
          return Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
      }

      return Math.round(length * 100) / 100 + ' ' + 'm';
  };

  formatArea(polygon,projection) {
    const area = getArea(polygon, {projection: projection});

    // Convert to hectares
    const hectares = area / 10000;

    if (hectares > 1) {
        return Math.round(hectares * 100) / 100 + ' ha';
    }

    return Math.round(area * 100) / 100 + ' m<sup>2</sup>';
}

  formatRadius(circle: CircleGeom) {
    const radius = circle.getRadius();
    if(radius > 100) {
      return Math.round((radius / 1000) * 100) / 100 + ' ' + 'km';
    }
    return Math.round(radius * 100) / 100 + ' ' + 'm';
  };


   transformLineString(lineString, sourceProj, destProj) {

    let sourceDef = olProj.get(sourceProj);
    let destDef = olProj.get(destProj);


    let transformedCoordinates = lineString.getCoordinates().map((coord) => {
        return    transform(coord,sourceDef,destDef)
    });
    return new LineString(transformedCoordinates);
}




}
