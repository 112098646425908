import { style } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, Signal, computed, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Form, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CreateEvent } from 'src/app/@core/events/createEvent';
import { StyleEditorComponent } from 'src/app/shared/components/style-renderer/style-editor/style-editor.component';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';

@Component({
  selector: 'app-create-feature-layer',
  templateUrl: './create-feature-layer.component.html',
  styleUrls: ['./create-feature-layer.component.css'],
  standalone: true,
  imports:[CommonModule, FormsModule, StyleEditorComponent,ReactiveFormsModule , MatFormFieldModule , MatInputModule]
})
export class CreateFeatureLayerComponent implements OnInit {

  @Output() createEvent = new EventEmitter<CreateEvent<CreateFeatureLayer>>();

form: FormGroup;
model: Signal<CreateFeatureLayer>;

  constructor() {

    this.form = new FormGroup({
      'name': new FormControl('', CustomValidators.required),
      'style': new FormControl('')
    });

    let formChanges = toSignal(this.form.valueChanges);

    this.model = computed(() => { return {name: formChanges().name,style: formChanges().style}});

   }

  ngOnInit() {
  }

  submit() {
      this.createEvent.emit(CreateEvent.create<CreateFeatureLayer>(this.model()))
    }
    cancel() {
      this.createEvent.emit(CreateEvent.cancel())
    }
    styleChanged(event) {

          this.form.get('style').setValue(event);
      }


}


export interface CreateFeatureLayer {
  name: string,
  style?: string
}
