import { Component, EventEmitter, OnInit, Output, Signal, WritableSignal, computed, effect, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { Circle, Fill, RegularShape, Stroke, Style } from 'ol/style';
import { NgxColorsModule, validColorValidator } from 'ngx-colors';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import {toSignal} from '@angular/core/rxjs-interop'

@Component({
  selector: 'app-point-editor',
  standalone: true,
  imports: [CommonModule, MaterialModule, NgxColorsModule, ReactiveFormsModule],
  templateUrl: './point-editor.component.html',
  styleUrl: './point-editor.component.css'
})
export class PointEditorComponent implements OnInit {

  @Output() styleChange = new EventEmitter();

  formState:Signal<{color: string, strokeColor: string, symbol: string, radius:number, strokeWidth:number}>;

  public form: FormGroup = new FormGroup(
    {
      colorInput: new FormControl('rgba(255,255,255,0.4)', validColorValidator()),
      color: new FormControl('rgba(255,255,255,0.4)'),
      symbol: new FormControl('Circle'),
      radius: new FormControl(5),
      strokeColor: new FormControl('#3399CC'),
      strokeColorInput: new FormControl('#3399CC', validColorValidator()),
      strokeWidth: new FormControl(1.25),

    },
    { updateOn: "change" }
  );

  constructor() {

    this.formState = toSignal(this.form.valueChanges,{});

    let fillColorTextControl = toSignal(this.form.controls["color"].valueChanges, null);
    let strokeColorTextControl = toSignal(this.form.controls["strokeColor"].valueChanges, null);

    effect(() => {
    this.form.get('colorInput').setValue(fillColorTextControl(), {emitEvent: false});
    });

    effect(() => {
      this.form.get('strokeColorInput').setValue(strokeColorTextControl(), {emitEvent: false});
    });




    let style = computed(() => {
     let state = this.formState();


     if(state == undefined)
     {
      return null;
     }

      let fill = new Fill({color: state.color});
      let stroke = new Stroke({color: state.strokeColor, width: state.strokeWidth});


      let imageStyle = this.getImageStyle(state.symbol, fill, stroke, state.radius)

      let style = new Style({image: imageStyle});

      return style;
    })

    this.form.valueChanges.subscribe(() => this.styleChange.emit(style()))
  }


  ngOnInit(): void {

  }

  getImageStyle(symbol, fill, stroke, radius)
  {
    switch(symbol)
    {
      case 'Square':
      return new RegularShape({
                fill: fill,
                stroke: stroke,
                points: 4,
                radius: radius,
                angle: Math.PI / 4,
              });
              case 'Triangle':
                return new RegularShape({
                  fill: fill,
                  stroke: stroke,
                  points: 3,
                  radius: radius,
                  angle: 0,
                });
                case 'Cross':
                  return new RegularShape({
                    fill: fill,
                    stroke: stroke,
                    points: 4,
                    radius: radius,
                    radius2: 0,
                    angle: 0,
                  });

      case 'X':

      return new RegularShape({
        fill: fill,
        stroke: stroke,
        points: 4,
        radius: radius,
        radius2: 0,
        angle: Math.PI / 4,
      })

      case 'Circle':
      default:
        return new Circle({radius: radius, fill: fill,stroke: stroke,})
    }
  }


}
