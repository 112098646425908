import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';

import { MapService } from '../data-access/map.service';
import { PropertiesService } from '../features/property/data-access/properties.service';
import { DigitizePropertyOverlayComponent } from '../features/property/features/digitize-property-overlay/digitize-property-overlay.component';
import { BottomSheetComponent } from '../features/property/ui/bottom-sheet/bottom-sheet.component';
import { SidebarComponent } from '../features/sidebar/sidebar.component';
import { GeocodeComponent } from '../ui/geocode/geocode.component';
import { ToolbarContainerComponent } from '../ui/toolbar-container/toolbar-container.component';
import { MeasureComponent } from '../ui/measure/measure.component';
import { MapInformationComponent } from '../ui/map-information/map-information.component';
import { ProjectionService } from '../data-access/projection.service';
import { AuthService } from 'src/app/@core/Auth/auth.service';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { AppSettingsService } from '../data-access/app-settings.service';
import { filter, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-map-container',
  templateUrl: './map-container.component.html',
  styleUrls: ['./map-container.component.css'],
  standalone: true,
  imports: [
    DigitizePropertyOverlayComponent,
    BottomSheetComponent,
    SidebarComponent,
    GeocodeComponent,
    ToolbarContainerComponent,
    MeasureComponent,
    MapInformationComponent,
  ],
})
export class MapContainerComponent implements OnInit, OnDestroy {
  public readonly mapService: MapService = inject(MapService);
  public readonly authService: AuthService = inject(AuthService);
  public readonly projectionService: ProjectionService =
    inject(ProjectionService);
  public readonly propertiesService: PropertiesService =
    inject(PropertiesService);
  public readonly modalService: ModalService = inject(ModalService);
  public readonly appSettingsService: AppSettingsService =
    inject(AppSettingsService);

  @ViewChild('map', { static: true }) map!: ElementRef;

  ngOnInit(): void {
    this.authService.isAuthenticated().subscribe();
    this.propertiesService.loadData();
    this.mapService.setTarget(this.map.nativeElement);
    this.mapService.updateSize();

    this.appSettingsService
      .getTestTermsAccepted()
      .pipe(
        filter((accepted) => !accepted),
        switchMap(() =>
          this.modalService.showTestMessage().pipe(
            tap((savePreference) => {
              this.appSettingsService.setTestTermsAccepted(savePreference);
            })
          )
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {}
}
