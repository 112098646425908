import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChildren,
  WritableSignal,
  effect,
  model,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CatalogueCategory,
  CatalogueGroup,
  CatalogueLayer,
} from '../../data-access/models/catalogue-data.model';
import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { CatalogueCategoriesComponent } from '../catalogue-categories/catalogue-categories.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { TestPipe } from 'src/app/shared/pipes/map-to-boolean.pipe';
import { accordionAnimation } from '../../utils/animations/accordion.animation';

@Component({
  selector: 'catalogue-groups',
  standalone: true,
  imports: [
    CommonModule,
    CdkAccordionModule,
    CatalogueCategoriesComponent,
    TooltipDirective,
    TestPipe,
  ],
  templateUrl: './catalogue-groups.component.html',
  styleUrl: './catalogue-groups.component.css',
  animations: [accordionAnimation],
})
export class CatalogueGroupsComponent implements OnChanges, AfterViewInit {
  @ViewChildren(CdkAccordionItem) accordionItems: CdkAccordionItem[];

  @Input()
  set expandedGroupID(value: number) {
    this._expandedGroupID.set(value);
  }

  get expandedGroupID(): number {
    return this._expandedGroupID();
  }

  @Input() expandedCategoryID: number = -1;

  @Input() catalogueData: CatalogueGroup[] = [];

  @Output() groupSelected = new EventEmitter();

  @Output() categorySelected = new EventEmitter();

  @Output() layerSelected = new EventEmitter();

  @Output() editLayer = new EventEmitter();

  @Output() editGroup = new EventEmitter();

  @Output() editCategory = new EventEmitter();

  @Output() addGroup = new EventEmitter();

  @Output() addCategory = new EventEmitter();

  public accordionState = new Map<
    number,
    WritableSignal<{ expanded: boolean }>
  >();

  public selectedGroup = model<CatalogueGroup>();

  public selectedCategory = model<CatalogueCategory>();

  public selectedLayer = model<CatalogueLayer>();

  private currentlyExpandedID = -1;

  private _expandedGroupID = signal(-1);

  constructor() {
    effect(
      () => {
        this.updateAccordionState(this._expandedGroupID());
      },
      { allowSignalWrites: true }
    );
  }

  ngOnChanges(changes) {
    if (this.catalogueData != null) {
      this.catalogueData.forEach((data) => {
        if (this.accordionState.has(data.dataCatalogueGroupID)) {
          this.accordionState.set(
            data.dataCatalogueGroupID,
            this.accordionState.get(data.dataCatalogueGroupID)
          );
        } else {
          this.accordionState.set(
            data.dataCatalogueGroupID,
            signal({ expanded: false })
          );
        }
      });
    }
  }

  ngAfterViewInit(): void {}

  edit(event, group: CatalogueGroup) {
    event.stopPropagation();

    this.editGroup.emit(group);
  }

  add() {
    this.addGroup.emit();
  }
  groupAccordionOpened(group: CatalogueGroup): void {
    this.updateAccordionState(group.dataCatalogueGroupID);
    this.selectedGroup.set(group);
  }

  groupAccordionClosed(group: CatalogueGroup): void {
    this.updateAccordionState(group.dataCatalogueGroupID);
    this.selectedCategory.set(null);
    this.layerSelected.emit(null);
  }

  updateAccordionState(dataLayerGroupID: number) {
    if (
      this.currentlyExpandedID != -1 &&
      this.currentlyExpandedID != dataLayerGroupID
    ) {
      this.accordionState
        .get(this.currentlyExpandedID)
        .set({ expanded: false });
    }

    this.accordionState.get(dataLayerGroupID)?.set({ expanded: true });

    this.currentlyExpandedID = dataLayerGroupID;
  }
}
