import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HeaderService } from './data-access/header.service';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'src/app/material/material.module';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';

@Component({
  selector: 'side-bar-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  standalone:true,
  imports:[SharedModule, MaterialModule , CommonModule, TooltipDirective, RouterModule]
})
export class HeaderComponent implements OnInit {

  @Input("sideNav") drawer: MatDrawer;

  @Input("pages") pages;

  _pages;

  @Output("openPage") _openPage = new EventEmitter<any>();

  constructor(public headerService:HeaderService, private route: ActivatedRoute, private router:Router)
  {

  }

  ngOnInit(): void {
    this._pages = Array.from(this.pages, (v) => v[1])

  }

  goBack(route:string| Array<string>): void {
this.headerService.navigateToParent(route);


  }

  test()
  {
    this.router.navigate(['../'], { relativeTo: this.route });
  }


  close()
  {
    this.drawer.close();
  }

}
