<form [formGroup]="form">

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Color</mat-label>
    <input matInput formControlName="inputCtrl" />
    <ngx-colors
      class="suffix"
      matSuffix
      ngx-colors-trigger
      colorPickerControls="only-alpha"
      formControlName="color"
    ></ngx-colors>
  </mat-form-field>

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Dash Pattern</mat-label>
    <input formControlName="dasharray" matInput type="number">
  </mat-form-field>

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Width</mat-label>
    <input formControlName="width" matInput type="number">
  </mat-form-field>


</form>

