import { Injectable, inject } from '@angular/core';
import { TokenService } from '../services/token.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, finalize, map, of, switchMap, tap, throwError } from 'rxjs';
import { authResponseDTO } from './models/authResponseDTO';
import { BaseEndpointService } from '../interfaces/IEndpoint';
import { Router } from '@angular/router';
import { getErrorMessage } from './models/errorMap';
import { passwordResetToken } from './components/reset-password/reset-password.component';

export const status = {
  successful: 'SUCCESSFUL',
  failed: 'FAILED',
  notStarted: 'NOT STARTED',
  refreshing: 'IN PROGRESS'
} as const;

type StatusValues = typeof status[keyof typeof status];


interface RefreshResult {
  isRefreshing: boolean;
  status: StatusValues;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseEndpointService {

  constructor() {

    super({route: 'account'})
  }

  resetPasswordWithToken(model:passwordResetToken)
  {
    return this.http.post(this.endpoint + '/token-password-reset',{
      ...model
    })
  }

  forgotPassword(email)
  {
    return this.http.post(this.endpoint + '/forgot-password', {email})
  }

}
