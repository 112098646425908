import { Component, computed, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import {
  CatalogueCategory,
  CatalogueGroup,
  CatalogueLayer,
} from '../../data-access/models/catalogue-data.model';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { DataCatalogueService } from '../../data-access/services/data-catalogue.service';
import { CdkAccordionModule } from '@angular/cdk/accordion';

import { CatalogueGroupsComponent } from '../../ui/catalogue-groups/catalogue-groups.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  Observable,
  combineLatest,
  filter,
  map,
  startWith,
  switchMap,
} from 'rxjs';
import { CustomDialogContainer } from 'src/app/@core/components/custom-dialog-container.component';
import { CreateEvent } from 'src/app/@core/events/createEvent';
import { CreateCatalogueCategoryDialogComponent } from '../add-update/features/catalogue-category/create-catalogue-category-dialog/create-catalogue-category-dialog.component';
import {
  CreateCatalogueCategory,
  CreateCatalogueGroup,
  CreateCatalogueLayer,
  UpdateCatalogueCategory,
  UpdateCatalogueGroup,
  UpdateCatalogueLayer,
} from '../add-update/data-access/models/create-update-catelogue.model';
import { CreateCatalogueLayerDialogComponent } from '../add-update/features/catalogue-layer/create-catalogue-layer-dialog/create-catalogue-layer-dialog.component';
import { CreateCatalogueGroupDialogComponent } from '../add-update/features/catalogue-group/create-catalogue-group-dialog/create-catalogue-group-dialog.component';
import { UpdateCatalogueGroupDialogComponent } from '../add-update/features/catalogue-group/update-catalogue-group-dialog/update-catalogue-group-dialog.component';
import { UpdateCatalogueCategoryDialogComponent } from '../add-update/features/catalogue-category/update-catalogue-category-dialog/update-catalogue-category-dialog.component';
import { UpdateCatalogueLayerDialogComponent } from '../add-update/features/catalogue-layer/update-catalogue-layer-dialog/update-catalogue-layer-dialog.component';

@Component({
  selector: 'app-catalogue-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CatalogueGroupsComponent,
    CdkAccordionModule,
    TooltipDirective,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './catalogue-dialog.component.html',
  styleUrl: './catalogue-dialog.component.css',
})
export class CatalogueDialogComponent {
  private readonly customDialog: Dialog = inject(Dialog);

  selectedGroup = signal<CatalogueGroup>(null);
  headerTitle = computed(() =>
    this.selectedGroup() != null
      ? this.selectedCategory() != null
        ? this.selectedLayer() != null
          ? this.selectedLayer().name
          : this.selectedCategory().name
        : this.selectedGroup().name
      : 'Select A Group'
  );
  selectedCategory = signal<CatalogueCategory>(null);
  selectedLayer = signal<CatalogueLayer>(null);

  searchField: FormControl = new FormControl('');

  filteredData$;
  expandedGroupID: number = -1;
  expandedCategoryID: number = -1;

  constructor(
    readonly catalogueService: DataCatalogueService,
    public dialogRef: DialogRef<string>
  ) {
    const searchTerm$: Observable<string> = this.searchField.valueChanges.pipe(
      startWith(this.searchField.value)
    );

    // this only filters down to the category, need to refine for layers possibly
    this.filteredData$ = combineLatest([
      catalogueService.catalogueData$,
      searchTerm$,
    ]).pipe(
      map(([catalogueData, searchTerm]) => {
        const searchTermLower = searchTerm.toLowerCase();

        return catalogueData.reduce((acc, group) => {
          const isGroupNameMatch = group.name
            .toLowerCase()
            .includes(searchTermLower);
          let groupToAdd = null;

          if (isGroupNameMatch) {
            groupToAdd = group;
          } else {
            const filteredCategories = group.categories.reduce(
              (matchingCategories, category) => {
                const isCategoryNameMatch = category.name
                  .toLowerCase()
                  .includes(searchTermLower);
                const matchingLayers = category.layers.filter((layer) =>
                  layer.name.toLowerCase().includes(searchTermLower)
                );

                if (isCategoryNameMatch || matchingLayers.length > 0) {
                  matchingCategories.push({
                    ...category,
                    layers: isCategoryNameMatch
                      ? category.layers
                      : matchingLayers,
                  });
                }

                return matchingCategories;
              },
              []
            );

            if (filteredCategories.length > 0) {
              groupToAdd = {
                ...group,
                categories: filteredCategories,
              };
            }
          }

          if (groupToAdd) {
            acc.push(groupToAdd);
          }

          return acc;
        }, []);
      })
    );
  }

  editLayer(layer: CatalogueLayer): void {
    this.selectedLayer.set(null);

    const instance = this.customDialog.open(
      UpdateCatalogueLayerDialogComponent,
      {
        container: CustomDialogContainer,
        data: layer,
      }
    ).componentInstance;

    instance.updateEvent
      .pipe(
        filter((event: CreateEvent<UpdateCatalogueLayer>) => event.create),
        switchMap((event: CreateEvent<UpdateCatalogueLayer>) =>
          this.catalogueService
            .updateLayer(event.model)
            .pipe(map(() => event.model))
        )
      )
      .subscribe((model) => {
        this.expandedCategoryID = model.DataCatalogueCategoryID;
      });
  }

  addGroup() {
    const instance = this.customDialog.open(
      CreateCatalogueGroupDialogComponent,
      {
        container: CustomDialogContainer,
      }
    ).componentInstance;

    instance.createEvent
      .pipe(
        filter((event: CreateEvent<CreateCatalogueGroup>) => event.create),
        switchMap((event: CreateEvent<CreateCatalogueGroup>) =>
          this.catalogueService.createGroup(event.model)
        )
      )
      .subscribe();
  }

  updateGroup(group: CatalogueGroup) {
    const instance = this.customDialog.open(
      UpdateCatalogueGroupDialogComponent,
      {
        container: CustomDialogContainer,
        data: group,
      }
    ).componentInstance;

    instance.updateEvent
      .pipe(
        filter((event: CreateEvent<UpdateCatalogueGroup>) => event.create),
        switchMap((event: CreateEvent<UpdateCatalogueGroup>) =>
          this.catalogueService
            .updateGroup(event.model)
            .pipe(map(() => event.model))
        )
      )
      .subscribe((model) => (this.expandedGroupID = model.id));
  }

  addCategory(dataLayerGroupID: string) {
    const instance = this.customDialog.open(
      CreateCatalogueCategoryDialogComponent,
      {
        container: CustomDialogContainer,
        data: { dataCatalogueGroupID: dataLayerGroupID },
      }
    ).componentInstance;

    instance.createEvent
      .pipe(
        filter((event: CreateEvent<CreateCatalogueCategory>) => event.create),
        switchMap((event: CreateEvent<CreateCatalogueCategory>) =>
          this.catalogueService
            .createCategory(event.model)
            .pipe(map(() => event.model))
        )
      )
      .subscribe(
        (model) => (this.expandedGroupID = model.DataCatalogueGroupID)
      );
  }

  updateCategory(category: CatalogueCategory) {
    const instance = this.customDialog.open(
      UpdateCatalogueCategoryDialogComponent,
      {
        container: CustomDialogContainer,
        data: category,
      }
    ).componentInstance;

    instance.updateEvent
      .pipe(
        filter((event: CreateEvent<UpdateCatalogueCategory>) => event.create),
        switchMap((event: CreateEvent<UpdateCatalogueCategory>) =>
          this.catalogueService
            .updateCategory(event.model)
            .pipe(map(() => event.model))
        )
      )
      .subscribe((model) => {
        this.expandedGroupID = model.DataCatalogueGroupID;
        this.expandedCategoryID = model.id;
      });
  }

  addLayer() {
    this.selectedLayer.set(null);

    const instance = this.customDialog.open(
      CreateCatalogueLayerDialogComponent,
      {
        container: CustomDialogContainer,
        data: {
          category: this.selectedCategory(),
          group: this.selectedGroup(),
        },
        disableClose: true,
      }
    ).componentInstance;

    instance.createEvent
      .pipe(
        filter((event: CreateEvent<CreateCatalogueLayer>) => event.create),
        switchMap((event: CreateEvent<CreateCatalogueLayer>) =>
          this.catalogueService
            .createLayer(event.model)
            .pipe(map(() => event.model))
        )
      )
      .subscribe((model) => {
        this.expandedCategoryID = model.DataCatalogueCategoryID;
      });
  }

  cancel() {
    this.dialogRef.close();
  }
}
