<sidebar>
  <app-map-information
    [currentCoordinates]="mapService.pointerCoordinate()"
    [currentUTMZone]="projectionService.CurrentUTMZone()"
  ></app-map-information>

  <geocode [map]="mapService.getMap()"></geocode>
  <toolbar-container>
    <map-measure [map]="mapService.getMap()"></map-measure>
  </toolbar-container>
  <div #map class="map"></div>
  <app-bottom-sheet></app-bottom-sheet>
</sidebar>
