import { CommonModule } from '@angular/common';
import { Component, OnInit, input } from '@angular/core';
import BaseLayer from 'ol/layer/Base';
import { StyleRendererComponent } from 'src/app/shared/components/style-renderer/style-renderer.component';
import { state } from '../../data-access/services/layers.service';
import { layersControl } from '../../utils/layers.control';
import { LayerOpacityDirective } from '../../utils/layer-opacity.directive';
import { LayerVisibilityDirective } from '../../utils/layer-visibility.directive';

@Component({
  selector: 'app-vector-layer',
  templateUrl: './vector-layer.component.html',
  styleUrls: ['./vector-layer.component.css'],
  standalone:true,
  imports:[CommonModule, StyleRendererComponent, LayerOpacityDirective, LayerVisibilityDirective]
})
export class VectorLayerComponent extends layersControl implements OnInit {

  layer = input<state>();

  ngOnInit() {
  }

}
