<div class="title-bar">
  <div class="title">Data Catalogue</div>
  <div class="window-controls">
      <button (click)="cancel()" ><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content">
  <div class="data-catalogue">
    <input [formControl]="searchField" type="text" pInputText placeholder="Search" />
    <catalogue-groups
      (editLayer)="editLayer($event)"
      (addCategory)="addCategory($event)"
      (editCategory)="updateCategory($event)"
      (addGroup)="addGroup()"
      (editGroup)="updateGroup($event)"
      [expandedGroupID]="expandedGroupID"
      [expandedCategoryID]="expandedCategoryID"
      [catalogueData]="filteredData$ | async"
      [(selectedGroup)]="selectedGroup"
      [(selectedCategory)]="selectedCategory"
      [(selectedLayer)]="selectedLayer"
      >

    </catalogue-groups>
  </div>

  <div>
    <div class="grid grid--column justify-between">
      <h3>{{headerTitle()}}</h3>
      <div class="grid grid--column gap--xl align-center">
        @if (selectedGroup())
        {
          <button class="button button--xl button--action button--brand" tooltipPosition="left"
          appTooltip="Add Layer" (click)="addLayer()">
          <i class="fa fa-plus"></i>
          </button>
        }

      </div>
    </div>
    @if (selectedGroup() && !selectedCategory() && !selectedLayer()) {

      @if (!selectedGroup().state().added) {
        <!-- <button class="button button--brand" (click)="catalogueService.addGroupToMap(selectedGroup())">Add Group & Continue</button>
        <button class="button button--brand"
                (click)="catalogueService.addGroupToMap(selectedGroup()); dialogRef.close()">Add Group & Close</button> -->
      }
      @else {
        <button class="button button--danger" (click)="selectedGroup().state().removeFn($event)">Remove Group & Continue</button>
        <button class="button button--danger"
        (click)="selectedGroup().state().removeFn($event); dialogRef.close()">Remove Group & Close</button>
      }
    }
    @else if(selectedCategory() && !selectedLayer())
    {

      @if (!selectedCategory().state().added) {
        <!-- <button class="button button--brand" (click)="catalogueService.addCategoryToMap(selectedGroup(),selectedCategory())">Add Category & Continue</button>
        <button class="button button--brand"
                (click)="catalogueService.addCategoryToMap(selectedGroup(),selectedCategory()); dialogRef.close()">Add Category & Close</button> -->
      }
      @else {
        <button class="button button--danger" (click)="selectedCategory().state().removeFn($event)">Remove Category & Continue</button>
        <button class="button button--danger"
        (click)="selectedCategory().state().removeFn($event); dialogRef.close()">Remove Category & Close</button>
      }

    }
    @else if(selectedLayer())
    {


      <h4>Type: {{selectedLayer().layerType}} Source: {{selectedLayer().sourceType}}</h4>
      <h5>Url: {{selectedLayer().url}}</h5>
      <h5>Attribution: {{selectedLayer().attribution}}</h5>
      @if(selectedLayer().params?.length > 0){

        @for(param of selectedLayer().params; track param)
        {
          <p>{{param[0]}}: {{param[1]}}</p>
        }
      }

      @if (!selectedLayer().state().added) {

      <button class="button button--brand" (click)="catalogueService.addCatalogueLayerToMap(selectedGroup(),selectedCategory(),selectedLayer())">Add Layer & Continue</button>
      <button class="button button--brand"
              (click)="catalogueService.addCatalogueLayerToMap(selectedGroup(),selectedCategory(),selectedLayer()); dialogRef.close()">Add Layer & Close</button>
      }
      @else {
        <button class="button button--danger" (click)="selectedLayer().state().removeFn($event)">Remove Layer & Continue</button>
        <button class="button button--danger"
        (click)="selectedLayer().state().removeFn($event); dialogRef.close()">Remove Layer & Close</button>
      }

    }
  </div>
</div>
<div class="footer">
</div>





