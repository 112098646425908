<form [formGroup]="form">

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Color</mat-label>
    <input matInput formControlName="colorInput" />
    <ngx-colors
      class="suffix"
      matSuffix
      ngx-colors-trigger
      colorPickerControls="only-alpha"
      formControlName="color"
    ></ngx-colors>
  </mat-form-field>

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Stroke Width</mat-label>
    <input formControlName="strokeWidth" matInput type="number">
  </mat-form-field>

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Stroke Color</mat-label>
    <input matInput formControlName="strokeColorInput" />
    <ngx-colors
      class="suffix"
      matSuffix
      ngx-colors-trigger
      colorPickerControls="only-alpha"
      formControlName="strokeColor"
    ></ngx-colors>
  </mat-form-field>

</form>

