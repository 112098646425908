import { Component, EventEmitter, OnDestroy, Output, inject } from '@angular/core';

import { InOut, InOut2, fadeInOut } from 'src/app/shared/animations/animations'
import { Draw } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { MultiPolygon, Polygon } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { Feature } from 'ol';
import { MapService } from 'src/app/map/data-access/map.service';

export class CreatePropertyEvent {

  create: boolean;
  feature?: Feature<any>;

  constructor(create, feature = null) {

    this.create = create;
    this.feature = feature;
  }

  static create(feature: Feature<any>)
  {
    return new CreatePropertyEvent(true,feature);
  }

  static cancel()
  {
    return new CreatePropertyEvent(false);
  }
}

@Component({
  selector: 'digitize-property-overlay',
  templateUrl: './digitize-property-overlay.component.html',
  styleUrls: ['./digitize-property-overlay.component.css'],
  animations:[fadeInOut],
  standalone:true
})
export class DigitizePropertyOverlayComponent implements OnDestroy {

  private readonly mapService: MapService = inject(MapService);
  private interaction: Draw;
  private map = this.mapService.getMap();
  public propertyBoundary: Feature<MultiPolygon>;
  private drawLayer: VectorLayer<any>;
  private drawSource: VectorSource;
  private coordinates: Array<Coordinate[][]> = [];

  @Output() createEvent = new EventEmitter<CreatePropertyEvent>();

  constructor()
  {
    this.setupDigitize();
  }

  setupDigitize()
  {
    this.drawSource = new VectorSource();
    this.drawLayer = new VectorLayer({
      source: this.drawSource
    });

    this.map.addLayer(this.drawLayer);


    this.interaction = new Draw({
      type: 'Polygon',
      source: this.drawSource
    });

    this.map.addInteraction(this.interaction);
    this.interaction.on('drawend', event => {


      let newCoordinates = (event.feature.getGeometry() as Polygon).getCoordinates() as any;

      this.coordinates.push(newCoordinates);

      if(!this.propertyBoundary)
      {
        this.propertyBoundary = new Feature(new MultiPolygon(this.coordinates));

        this.drawSource.addFeature(this.propertyBoundary)
      }
      else
      {
        this.propertyBoundary.getGeometry().setCoordinates(this.coordinates);
      }


      this.drawSource.removeFeature(event.feature);
    })
  }

  finished()
  {
   this.createEvent.emit(CreatePropertyEvent.create(this.propertyBoundary));
  }

  cancel()
  {
    this.createEvent.emit(CreatePropertyEvent.cancel())
  }

  undo()
  {
    this.interaction.removeLastPoint();
  }

  ngOnDestroy(): void {
      this.map.removeInteraction(this.interaction);
      this.map.removeLayer(this.drawLayer);
      this.drawSource.clear();
  }



}
