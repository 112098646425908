import { Component, EventEmitter, Output, Signal, computed, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgxColorsModule, validColorValidator } from 'ngx-colors';
import { MaterialModule } from 'src/app/material/material.module';
import { toSignal } from '@angular/core/rxjs-interop';
import { Stroke, Style } from 'ol/style';

@Component({
  selector: 'app-line-editor',
  standalone: true,
  imports: [CommonModule,MaterialModule, NgxColorsModule, ReactiveFormsModule, ],
  templateUrl: './line-editor.component.html',
  styleUrl: './line-editor.component.css'
})
export class LineEditorComponent {

  @Output() styleChange = new EventEmitter();

  formState:Signal<{color: string, dasharray:number, width:number}>;

  public form: FormGroup = new FormGroup(
    {
      inputCtrl: new FormControl('#3399CC', validColorValidator()),
      dasharray: new FormControl(0),
      width: new FormControl(1.25),
      color: new FormControl('#3399CC'),
    },
    { updateOn: "change" }
  );


  constructor() {
    this.formState = toSignal(this.form.valueChanges,{});


      let fillColorTextControl = toSignal(this.form.controls["color"].valueChanges, null);

      effect(() => {
      this.form.get('inputCtrl').setValue(fillColorTextControl(), {emitEvent: false});
      });


    let style = computed(() => {
      let state = this.formState();


     if(state == undefined)
     {
      return null;
     }

       let stroke = new Stroke({color: state.color, width: state.width, lineDash:[state.dasharray]});

       let style = new Style({stroke: stroke});

      return style;
     })

     this.form.valueChanges.subscribe(() => this.styleChange.emit(style()))
   }

   ngOnInit(): void {


  }


  }



