import { Injectable, inject } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';
import { MapChildRoutes } from 'src/app/map/util/map-pages.config';


export type SidebarData = {
  route: string,
  title: string,
  className: string,
  active: boolean
  disabled: boolean,
  position: number
}
@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private pages:Map<String,SidebarData> =new Map();

  public drawer: MatDrawer;

  opened: BehaviorSubject<boolean> = new BehaviorSubject(false);



  constructor(public route:ActivatedRoute, public router:Router) {

    MapChildRoutes.filter(page => page.data).sort((a,b) => a.data.sidebar.position - b.data.sidebar.position ).forEach(page => this.pages.set(page.path.includes('/:') ? page.path.split('/:')[0] : page.path, page.data.sidebar))


    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.deactivateAllPages()
      let url = event.urlAfterRedirects;
      let pageName = url.split('/')[2];
      if (this.pages.has(pageName)) {
        this.pages.get(pageName).active = true;
      }
    });
  }


  setDrawer(drawer: MatDrawer)
  {
    this.drawer = drawer;
  }

  close()
  {
    this.drawer.close();
  }

  open()
  {

    this.drawer.open();

  }



  getPages()
  {
    return this.pages;
  }

  private deactivateAllPages() {
    this.pages.forEach(page => page.active = false);
  }
}
