import { Injectable, effect, inject, signal } from '@angular/core';
import { Feature, View } from 'ol';
import OlMap from 'ol/Map';
import { Attribution, ScaleLine } from 'ol/control';
import LayerGroup from 'ol/layer/Group';
import { BasemapsService } from './Basemaps.service';
import * as olProj from 'ol/proj';
import Wkt from 'ol/format/WKT';
import { transform } from 'ol/proj';
import { Select } from 'ol/interaction';
import { HttpClient } from '@angular/common/http';
import { DataCatalogueService } from '../features/data-catalogue/data-access/services/data-catalogue.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { PropertiesService } from '../features/property/data-access/properties.service';
import { TileWMS } from 'ol/source';
import TileLayer from 'ol/layer/Tile';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private readonly basemapsService: BasemapsService = inject(BasemapsService);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly dataCatalogueService: DataCatalogueService =
    inject(DataCatalogueService);
  private readonly propertiesService: PropertiesService =
    inject(PropertiesService);
  private readonly initialCenter = [146.6, -30.83];
  private readonly initialZoom = 5;
  private readonly boundingExtent = [110, -47, 180, -8];
  public readonly featureMap: Map<string, Feature> = new Map();
  interaction: Select;
  public pointerCoordinate = signal(null);

  selection: {} = {};

  map!: OlMap;
  selectionLayer: any;
  constructor() {
    this.init();

    this.dataCatalogueService.removeLayer.subscribe((layer) => {
      if (!layer) return;

      this.map.getLayers().remove(layer);
    });
    this.dataCatalogueService.addLayer.subscribe((layer) => {
      if (!layer) return;

      let index = this.map
        .getLayers()
        .getArray()
        .indexOf(this.propertiesService.propertyGroup);

      this.map.getLayers().insertAt(index, layer);
    });

    this.propertiesService.clearEvent.subscribe(() => {
      this.featureMap.clear();
    });

    this.propertiesService.propertyLoadedEvent.subscribe((data) => {
      this.featureMap.set(data.id, data.feature);
    });
  }

  init() {
    const wmsSource = new TileWMS({
      url: 'https://spatial.infrastructure.gov.au/server/services/National_Broadband_Network_%E2%80%93_Connections_by_technology_type_%E2%80%93_July_2020/MapServer/WMSServer',
      params: {
        LAYERS: '0',
        TILED: true,
      },
      transition: 0,
    });

    const wmsLayer = new TileLayer({
      source: wmsSource,
    });

    this.map = new OlMap({
      layers: [this.propertiesService.propertyGroup],
      view: new View({
        center: transform(this.initialCenter, 'EPSG:4326', 'EPSG:3857'),
        zoom: this.initialZoom,
        maxZoom: 23,
      }),
      controls: [
        new Attribution(),
        new ScaleLine({
          bar: true,
          minWidth: 150,
        }),
      ],
    });

    this.setupBaseMaps();

    this.map.on('rendercomplete', (e) => {
      this.map.updateSize();
    });

    this.map.on('dblclick', (e) => {
      this.propertiesService.handleDoubleClick(e, this.map, (id) => {
        this.zoomToFeature(id, 25);
      });
    });

    this.map.on('pointermove', (event) => {
      if (event.dragging) {
        return;
      }

      let coord = event.coordinate;

      let transformedCoord = olProj.toLonLat(coord);

      this.pointerCoordinate.set(transformedCoord);
    });
  }

  public convertWktToFeature(wkt: string): Feature<any> {
    const format = new Wkt();

    return format.readFeature(wkt);
  }

  public convertFeatureToWKT(feature: Feature<any>) {
    const format = new Wkt();

    return format.writeFeature(feature);
  }

  async setupBaseMaps() {
    let layers = await this.basemapsService.buildLayers();
    let basemapsGroup = new LayerGroup({
      properties: { title: 'Background Maps' },
      layers: layers,
    });
    this.map.getLayers().insertAt(0, basemapsGroup);
  }

  getMap(): OlMap {
    return this.map;
  }

  setTarget(element) {
    this.map.setTarget(element);
    this.map.updateSize();
  }

  updateSize() {
    this.map.updateSize();
  }

  zoomToFeature(id: string, yPadding: number) {
    let feature = this.featureMap.get(id);
    const padding = [20, 20, yPadding + 40, 20];
    this.map
      .getView()
      .fit(feature.getGeometry().getExtent(), { padding: padding });
  }

  getMapExtent() {
    return this.map.getView().calculateExtent();
  }
}
