import { inject, Injectable } from '@angular/core';
import { View } from 'ol';
import OlMap from 'ol/Map';
import { transform, transformExtent } from 'ol/proj';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import VectorImageLayer from 'ol/layer/VectorImage';
import { TileArcGISRest, TileWMS, XYZ } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { BasemapsService } from '../../../../../../data-access/Basemaps.service';
import GeoJSON from 'ol/format/GeoJSON';
import { Tile } from 'ol/layer';
import { DataCatalogueService } from '../../../../data-access/services/data-catalogue.service';

@Injectable({
  providedIn: 'root',
})
export class MiniMapService {
  private readonly basemapsService: BasemapsService = inject(BasemapsService);
  private readonly dataCatalogueService: DataCatalogueService =
    inject(DataCatalogueService);

  private readonly initialCenter = [146.6, -41.83];
  private readonly initialZoom = 6;
  private readonly boundingExtent = [110, -47, 180, -8];

  map!: OlMap;
  constructor() {
    this.init();
  }

  init() {
    this.map = new OlMap({
      layers: [],
      view: new View({
        center: transform(this.initialCenter, 'EPSG:4326', 'EPSG:3857'),
        zoom: this.initialZoom,
        extent: transformExtent(this.boundingExtent, 'EPSG:4326', 'EPSG:3857'),
        maxZoom: 23,
      }),
      controls: [],
    });

    this.setupBaseMaps();
    this.map.on('rendercomplete', () => {
      this.map.updateSize();
    });
  }

  async setupBaseMaps() {
    let layers = await this.basemapsService.buildLayers();
    let basemapsGroup = new LayerGroup({
      properties: { title: 'Background Maps' },
      layers: layers,
    });
    this.map.getLayers().insertAt(0, basemapsGroup);
  }

  setTarget(element) {
    this.map.setTarget(element);
    this.map.updateSize();
  }

  updateSize() {
    this.map.updateSize();
  }

  addAndCheckNewLayer(
    url: string,
    sourceType: number,
    layerName: string,
    attribution?: string
  ): void {
    let layer: VectorLayer<any> | TileLayer<any> | VectorImageLayer<any>;
    switch (sourceType) {
      case 0:
        layer = new TileLayer({
          source: new TileWMS({
            url: url,
            params: { LAYERS: layerName, TILED: true },
          }),
          visible: true,
        });
        break;
      case 1:
      case 3:
        layer = new VectorImageLayer({
          source: new VectorSource({
            url: this.dataCatalogueService.processUrl(url),
            format: new GeoJSON(),
          }),
          visible: true,
        });
        break;
      case 2:
        layer = new TileLayer({
          source: new XYZ({
            url: url,
            projection: this.dataCatalogueService.getProjection(url),
            attributions: attribution ? attribution : '',
            /*minZoom: definition.min_zoom
              ? definition.min_zoom
              : this.basemapsService.default_min_zoom,
            maxZoom: definition.min_zoom
              ? definition.min_zoom
              : this.basemapsService.default_max_zoom,*/
          }),
          visible: true,
        });
        break;
      case 4:
        layer = new Tile({
          source: new TileArcGISRest({
            url: url,
          }),
          visible: true,
        });
        break;
      default:
        break;
    }

    if (layer) {
      console.log(this.map.getAllLayers());
      this.map.addLayer(layer);
      this.map.getView().fit(layer.getSource().getExtent());
    }
  }

  private minZoom(url: string) {}
  private maxZoom(url: string) {}
}
