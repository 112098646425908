import { Component, OnInit, Signal, computed, effect, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureLayersService } from '../../data-access/feature-layers.service';
import { Observable, filter, map, switchMap } from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MaterialModule } from 'src/app/material/material.module';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';
import { DrawFeaturesOverlayComponent } from '../draw-features-overlay/draw-features-overlay.component';
import { LayerDTO } from '../../../../../layers/layers.component';
import {CdkMenu, CdkMenuItem, CdkMenuTrigger} from '@angular/cdk/menu';
import {toSignal} from '@angular/core/rxjs-interop'
import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import { StyleRendererComponent } from 'src/app/shared/components/style-renderer/style-renderer.component';
import { StyleEditorComponent } from 'src/app/shared/components/style-renderer/style-editor/style-editor.component';
import { SelectPropertyOverlayComponent } from '../../../../ui/select-property-overlay/select-property-overlay.component';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-feature-layers',
  standalone: true,
  imports: [CommonModule,MaterialModule, ReactiveFormsModule, DrawFeaturesOverlayComponent,CdkAccordionModule,CdkMenuTrigger, CdkMenu, CdkMenuItem, SelectPropertyOverlayComponent, CdkDropList, CdkDrag, StyleEditorComponent, StyleRendererComponent],
  templateUrl: './feature-layers.component.html',
  styleUrl: './feature-layers.component.css',
})
export class FeatureLayersComponent implements OnInit {

  public readonly featureLayerService: FeatureLayersService = inject(FeatureLayersService);
  private readonly modalService:ModalService = inject(ModalService);

  public form: UntypedFormGroup;

  public style;

  featureLayers:Signal<LayerDTO[]>;
  private readonly params;

  test = signal('Fill')

  constructor(activatedRoute:ActivatedRoute) {



    this.featureLayers = toSignal(this.featureLayerService.getLayers(), {initialValue: []});

    this.params = toSignal(activatedRoute.paramMap, {initialValue:null});

    let test = computed(() => this.params().get('id'));

    this.featureLayerService.setPropertyID(test());
    this.featureLayerService.loadLayers();



   effect(() => console.log(this.params()))

  }
  ngOnInit(): void {

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [CustomValidators.required]),
    });

  }

  onExpandedChange(accordionItem : CdkAccordionItem, layerID)
  {
    this.featureLayerService.setLayerState(layerID, {expanded:accordionItem.expanded})
  }


  createLayer()
  {
    this.modalService.showCreateLayerDialog().pipe(
      filter(result => result.create),
      switchMap(result => this.featureLayerService.createLayer(result.model.name, result.model.style)))
      .subscribe();
  }

  deleteLayer(layer)
  {
   this.modalService.showConfirmDelete("Feature Layer",layer.name ).pipe(switchMap(data => this.featureLayerService.deleteLayer(layer.layerID))).subscribe();
  }

  downloadFile(layerID, srid: number = 4326): void {

    this.modalService.showDownloadLayer().pipe(switchMap(data => {
      return this.featureLayerService.downloadGeoJson(layerID, data.srid)
    })).subscribe(response => {
      const blob = new Blob([response.body], { type: 'application/geo+json' });

      const contentDisposition = response.headers.get('Content-Disposition');

      let filename = 'download.json';
      if (contentDisposition) {
         filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }

      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = filename;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }

  drop(event)
  {
    console.log(this.featureLayerService.getKeyByValue(event.previousIndex));
  }

}


