import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Feature } from 'ol';
import { ConfirmationComponent } from '../../../shared/components/dialogs/confirmation/confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/components/dialogs/delete-confirmation/delete-confirmation.component';
import { ErrorComponent } from '../../../shared/components/dialogs/error/error.component';
import { CreateFeatureDialogComponent } from 'src/app/map/features/property/features/feature-layers/ui/create-feature-dialog/create-feature-dialog.component';
import { DownloadLayerDialogComponent } from 'src/app/map/features/property/features/feature-layers/ui/download-layer-dialog/download-layer-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { CustomDialogContainer } from '../../components/custom-dialog-container.component';
import { of, tap } from 'rxjs';
import { EditPropertyComponent } from 'src/app/map/features/property/features/edit-property/edit-property.component';
import { CreateFeatureLayerComponent } from 'src/app/map/features/property/features/feature-layers/ui/create-feature-layer/create-feature-layer.component';
import { CatalogueDialogComponent } from 'src/app/map/features/data-catalogue/features/catalogue-dialog/catalogue-dialog.component';
import { TestEnvironmentConfirmationComponent } from 'src/app/shared/components/dialogs/test-environment-confirmation/test-environment-confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly customDialog = inject(Dialog);

  constructor() {}

  showError(error: Error) {
    return this.dialog
      .open(ErrorComponent, {
        data: { errorMessage: error.message },
        maxWidth: '500px',
      })
      .afterClosed();
  }

  showConfirmation(displayText: string) {
    return this.dialog
      .open(ConfirmationComponent, {
        data: { displayText: displayText },
      })
      .afterClosed();
  }

  showTestMessage() {
    let ref = this.customDialog.open(TestEnvironmentConfirmationComponent, {
      container: CustomDialogContainer,
    });

    ref.disableClose = true;

    return ref.componentInstance.savePreferenceChange.pipe(tap((data) => ref.close()));


    return this.dialog
      .open(TestEnvironmentConfirmationComponent, {
        data: { messsage: 'test' },
      })
      .afterClosed();
  }

  showCreateFeature(feature: Feature) {
    return this.dialog
      .open(CreateFeatureDialogComponent, {
        hasBackdrop: true,
        panelClass: 'myDialogClass',
        width: '500px',
        data: { feature: feature },
      })
      .afterClosed();
  }

  showConfirmDelete(entityType: string, entityDetails: string) {
    let ref = this.customDialog.open(DeleteConfirmationComponent, {
      container: CustomDialogContainer,
      data: { type: entityType, details: entityDetails },
    });

    ref.disableClose = true;

    return ref.componentInstance.deleteEvent.pipe(
      tap((data) => {
        ref.close();
      })
    );
  }

  showDownloadLayer() {
    return this.dialog
      .open(DownloadLayerDialogComponent, {
        panelClass: 'myDialogClass',
      })
      .afterClosed();
  }

  showPropertyEditDialog(data) {
    let ref = this.customDialog.open(EditPropertyComponent, {
      container: CustomDialogContainer,
      data: data,
    });
    ref.disableClose = true;

    return ref.componentInstance.createEvent.pipe(
      tap((data) => {
        ref.close();
      })
    );
  }

  showCreateLayerDialog() {
    let ref = this.customDialog.open(CreateFeatureLayerComponent, {
      container: CustomDialogContainer,
    });
    ref.disableClose = true;

    return ref.componentInstance.createEvent.pipe(tap(() => ref.close()));
  }

  showDataCatalogue() {
    let ref = this.customDialog.open(CatalogueDialogComponent, {
      container: CustomDialogContainer,
    });

    return ref.componentInstance;
  }
}
