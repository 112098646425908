import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, filter, map, switchMap, tap } from 'rxjs';
import {
  Catalogue,
  CatalogueCategory,
  CatalogueGroup,
  CatalogueLayer,
} from '../models/catalogue-data.model';
import { BaseEndpointService } from 'src/app/@core/interfaces/IEndpoint';
import { CreateCatalogueGroupDialogComponent } from '../../features/add-update/features/catalogue-group/create-catalogue-group-dialog/create-catalogue-group-dialog.component';
import { CustomDialogContainer } from '../../../../../@core/components/custom-dialog-container.component';
import { CreateEvent } from '../../../../../@core/events/createEvent';
import {
  CreateCatalogueCategory,
  CreateCatalogueGroup,
} from '../../features/add-update/data-access/models/create-update-catelogue.model';
import { FormGroup } from '@angular/forms';
import { Dialog } from '@angular/cdk/dialog';
import { CreateCatalogueCategoryDialogComponent } from '../../features/add-update/features/catalogue-category/create-catalogue-category-dialog/create-catalogue-category-dialog.component';
import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import VectorImageLayer from 'ol/layer/VectorImage';
import { TileArcGISRest, TileWMS, XYZ } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Tile } from 'ol/layer';
import { BasemapsService } from '../../../../data-access/Basemaps.service';
import LayerGroup from 'ol/layer/Group';
import BaseLayer from 'ol/layer/Base';
import {
  ArcGISMapLayers,
  WFSCapability,
  WMSCapability,
} from '../models/getcapabilities.model';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import { FeatureLike } from 'ol/Feature';
import CircleStyle from 'ol/style/Circle';
import { Type } from 'ol/geom/Geometry';

type state = {
  id: string | number;
  added: boolean;
  children?: WritableSignal<state>[];
  layer?: BaseLayer | LayerGroup;
  removeFn?: (event) => {};
};

/*
 I have added BaseEndpointService to your service to match the other data access services I have.
 at the moment it only has 2 fields

   protected endpoint: string;
  protected readonly http:HttpClient = inject(HttpClient);

  the endpoint is set in the constructor and appended with the route you specify in super({route: 'data-catalogue})

  so in this case it will end up being https://localhost:7008/api/v1/data-catalogue
*/
@Injectable({
  providedIn: 'root',
})
export class DataCatalogueService extends BaseEndpointService {
  public catalogueData$: BehaviorSubject<Catalogue> =
    new BehaviorSubject<Catalogue>([]);
  public catalogueGroups$: BehaviorSubject<CatalogueGroup[]> =
    new BehaviorSubject<CatalogueGroup[]>([]);
  public catalogueCategories$: BehaviorSubject<CatalogueCategory[]> =
    new BehaviorSubject<CatalogueCategory[]>([]);

  public addedLayers: Map<CatalogueGroup, CatalogueCategory[]>;

  public readonly dataCatalogueGroupState = new Map<
    number,
    WritableSignal<state>
  >();

  public removeLayer = new BehaviorSubject(null);

  public addLayer = new BehaviorSubject(null);

  private readonly baseMapsService: BasemapsService = inject(BasemapsService);
  private readonly _WMS_CAPABILITIES_ENDPOINT: string =
    `${this.endpoint}/wms-capabilities?url=` as const;

  private readonly _WFS_CAPABILITIES_ENDPOINT: string =
    `${this.endpoint}/wfs-capabilities?url=` as const;

  private readonly _ARC_GIS_REST_MAP_LAYERS_ENDPOINT: string =
    `${this.endpoint}/arc-gis-rest-map-layers?url=` as const;

  private readonly _ARC_GIS_REST_FEATURE_LAYERS_ENDPOINT: string =
    `${this.endpoint}/arc-gis-rest-feature-layers?url=` as const;

  private _COLOUR_LIST: string[] = [
    '#FFD1DC',
    '#FFCF99',
    '#FFFF99',
    '#B0E57C',
    '#99C5C4',
    '#C5A3FF',
    '#FFB3BA',
    '#FFDFBA',
    '#FFFFBA',
    '#BAFFC9',
    '#BAE1FF',
    '#D1BBFF',
    '#FFB347',
    '#77DD77',
    '#AEC6CF',
  ];

  private _OPAQUE_COLOUR_LIST: string[] = [
    '#FFD1DC80',
    '#FFCF9980',
    '#FFFF9980',
    '#B0E57C80',
    '#99C5C480',
    '#C5A3FF80',
    '#FFB3BA80',
    '#FFDFBA80',
    '#FFFFBA80',
    '#BAFFC980',
    '#BAE1FF80',
    '#D1BBFF80',
    '#FFB34780',
    '#77DD7780',
    '#AEC6CF80',
  ];

  private _COLOUR_LIST_TEMP: string[] = [...this._COLOUR_LIST];
  private _OPAQUE_COLOUR_LIST_TEMP: string[] = [...this._OPAQUE_COLOUR_LIST];

  constructor() {
    super({ route: 'data-catalogue' });
    this.addedLayers = new Map<CatalogueGroup, CatalogueCategory[]>();
    this.loadCatalogue().subscribe();
  }

  private CreateStateSignal(existingState, id, name) {
    if (existingState == null) {
      existingState = signal({
        id: id,
        added: false,
        children: [],
        layer: new LayerGroup({
          properties: {
            title: name,
          },
        }),
      });
    }
    return existingState;
  }

  loadCatalogue() {
    return this.http.get<Catalogue>(this.endpoint).pipe(
      map((catalogue) => {
        let updatedCatalogue = catalogue.reduce(
          (accCatalogue, catalogueGroup) => {
            let groupState = this.dataCatalogueGroupState.get(
              catalogueGroup.dataCatalogueGroupID
            );

            if (!groupState) {
              groupState = this.CreateStateSignal(
                groupState,
                catalogueGroup.dataCatalogueGroupID,
                catalogueGroup.name
              );
              this.dataCatalogueGroupState.set(
                catalogueGroup.dataCatalogueGroupID,
                groupState
              );
            }

            const updatedCategories = catalogueGroup.categories.reduce(
              (accCategories, catalogueCategory) => {
                let categoryState = groupState().children.find(
                  (child) =>
                    child().id === catalogueCategory.dataCatalogueCategoryID
                );

                if (!categoryState) {
                  categoryState = this.CreateStateSignal(
                    categoryState,
                    catalogueCategory.dataCatalogueCategoryID,
                    catalogueCategory.name
                  );
                  groupState.set({
                    ...groupState(),
                    children: [...groupState().children, categoryState],
                  });
                }

                const updatedLayers = catalogueCategory.layers.reduce(
                  (accLayers, catalogueLayer) => {
                    let layerState = categoryState().children.find(
                      (child) =>
                        child().id === catalogueLayer.dataCatalogueLayerID
                    );

                    if (!layerState) {
                      layerState = this.CreateStateSignal(
                        layerState,
                        catalogueLayer.dataCatalogueLayerID,
                        catalogueLayer.name
                      );
                      categoryState.set({
                        ...categoryState(),
                        children: [...categoryState().children, layerState],
                      });
                    }

                    catalogueLayer.state = layerState;
                    accLayers.push(catalogueLayer);
                    return accLayers;
                  },
                  []
                );

                catalogueCategory.layers = updatedLayers;
                catalogueCategory.state = categoryState;
                accCategories.push(catalogueCategory);
                return accCategories;
              },
              []
            );

            catalogueGroup.categories = updatedCategories;
            accCatalogue.push({ ...catalogueGroup, state: groupState });
            return accCatalogue;
          },
          []
        );
        return updatedCatalogue;
      }),
      tap((catalogue: Catalogue) => {
        this.catalogueData$.next(catalogue);
      })
    );
  }

  loadCatalogueGroups() {
    return this.http.get<CatalogueGroup[]>(`${this.endpoint}/groups`).pipe(
      tap((data: CatalogueGroup[]) => {
        this.catalogueGroups$.next(data);
      })
    );
  }

  loadCatalogueCategories() {
    return this.http
      .get<CatalogueCategory[]>(`${this.endpoint}/categories`)
      .pipe(
        tap((data: CatalogueCategory[]) => {
          this.catalogueCategories$.next(data);
        })
      );
  }

  loadCatalogueLayerSources() {
    return this.http.get<any[]>(`${this.endpoint}/layers/sources`);
  }

  loadCatalogueLayerTypes() {
    return this.http.get(`${this.endpoint}/layers/types`);
  }

  //what I am doing is switching to the loadCatalogue Observable but returning the original response down the chain,
  // this is so that we can retain the ID which we need to set the select.
  createGroup(request) {
    return this.http
      .post<string>(`${this.endpoint}/groups`, request)
      .pipe(
        switchMap((response) => this.loadCatalogue().pipe(map(() => response)))
      );
  }

  updateGroup(request) {
    return this.http
      .patch<string>(`${this.endpoint}/groups/${request.id}`, {
        Name: request.Name,
      })
      .pipe(
        switchMap((response) => this.loadCatalogue().pipe(map(() => response)))
      );
  }

  createCategory(request) {
    return this.http
      .post<string>(`${this.endpoint}/categories`, request)
      .pipe(
        switchMap((response) => this.loadCatalogue().pipe(map(() => response)))
      );
  }

  updateCategory(request) {
    return this.http
      .patch<string>(`${this.endpoint}/categories/${request.id}`, {
        Name: request.Name,
        Description: request.Description,
        DataCatalogueGroupID: request.DataCatalogueGroupID,
      })
      .pipe(
        switchMap((response) => this.loadCatalogue().pipe(map(() => response)))
      );
  }

  createLayer(request) {
    return this.http
      .post(`${this.endpoint}/layers`, request)
      .pipe(switchMap(() => this.loadCatalogue()));
  }

  updateLayer(request) {
    return this.http
      .patch(`${this.endpoint}/layers/${request.id}`, {
        Name: request.Name,
        Description: request.Description,
        Url: request.Url,
        Attribution: request.Attribution,
        LayerType: request.LayerTypeID,
        SourceType: request.SourceTypeID,
        DataCatalogueCategoryID: request.DataCatalogueCategoryID,
        Params: request.params,
      })
      .pipe(switchMap(() => this.loadCatalogue()));
  }

  addGroupToMap(group: CatalogueGroup) {
    group.categories.forEach((category) => {
      this.addCategoryToMap(group, category);
    });
  }

  addCategoryToMap(group: CatalogueGroup, category: CatalogueCategory): void {
    category.layers.forEach((layer) => {
      this.addCatalogueLayerToMap(group, category, layer);
    });
  }

  addCatalogueLayerToMap(
    group: CatalogueGroup,
    category: CatalogueCategory,
    layer: CatalogueLayer
  ) {
    let groupState = group.state();

    let catalogueCategoryState = groupState.children.find(
      (child) => child().id === category.dataCatalogueCategoryID
    );

    if (!catalogueCategoryState) {
      return;
    }

    let catalogueLayerState = catalogueCategoryState().children.find(
      (child) => child().id === layer.dataCatalogueLayerID
    );

    if (!catalogueLayerState) {
      return;
    }

    if (!catalogueLayerState().added) {
      let mapLayer = this.generateLayer(layer);

      let test = catalogueCategoryState().layer as LayerGroup;

      const layerRemoveFn = () => {
        test.getLayers().remove(mapLayer);
        catalogueLayerState.set({
          ...catalogueLayerState(),
          layer: null,
          added: false,
        });

        if (test.getLayers().getLength() === 0) {
          test.get('removeFn')();
        }
      };

      mapLayer.set('removeFn', layerRemoveFn);

      catalogueLayerState.set({
        ...catalogueLayerState(),
        layer: mapLayer,
        added: true,
        removeFn: layerRemoveFn,
      });

      test.getLayers().push(mapLayer);
    }

    if (!catalogueCategoryState().added) {
      let test = groupState.layer as LayerGroup;

      let groupRemoveFunction = (event) => {
        if (event) {
          event.stopPropagation();
        }

        test.getLayers().remove(catalogueCategoryState().layer);
        catalogueCategoryState().layer.getLayers().clear();

        catalogueCategoryState.set({
          ...catalogueCategoryState(),
          added: false,
        });

        catalogueCategoryState().children.forEach((layerState) => {
          layerState.set({ ...layerState(), layer: null, added: false });
        });

        if (test.getLayers().getLength() === 0) {
          test.get('removeFn')();
        }
      };

      catalogueCategoryState().layer.set('removeFn', groupRemoveFunction);
      test.getLayers().push(catalogueCategoryState().layer);
      catalogueCategoryState.set({
        ...catalogueCategoryState(),
        added: true,
        removeFn: groupRemoveFunction,
      });
    }

    if (!groupState.added) {
      const groupRemoveFunction = (event) => {
        if (event) {
          event.stopPropagation();
        }

        this.removeLayer.next(groupState.layer);
        // this.mapService.getMap().getLayers().remove(groupState.layer)
        group.state().children.forEach((category) => {
          category.set({ ...category(), added: false });

          category().children.forEach((layerState) => {
            layerState.set({ ...layerState(), layer: null, added: false });
          });
        });

        var layersCollection = groupState.layer.getLayers();

        layersCollection.forEach((layer) => {
          if (layer instanceof LayerGroup) {
            layer.getLayers().clear();
          }
        });
        layersCollection.clear();
        group.state.set({ ...group.state(), added: false });
      };

      groupState.layer.set('removeFn', groupRemoveFunction);

      // this.mapService.getMap().getLayers().push(groupState.layer);

      this.addLayer.next(groupState.layer);
      group.state.set({
        ...groupState,
        added: true,
        removeFn: groupRemoveFunction,
      });
    }
  }

  createNewGroup(
    form: FormGroup,
    dialog: Dialog,
    loadDataTrigger$: BehaviorSubject<void>
  ) {
    let instance = dialog.open(CreateCatalogueGroupDialogComponent, {
      container: CustomDialogContainer,
    }).componentInstance;

    instance.createEvent
      .pipe(
        filter((event: CreateEvent<CreateCatalogueGroup>) => event.create),
        switchMap((event: CreateEvent<CreateCatalogueGroup>) =>
          this.createGroup(event.model)
        )
      )
      .pipe(tap(() => loadDataTrigger$.next()))
      .subscribe((response) => {
        let parts = response.split(':');
        const id = parseInt(parts[1].trim());
        form.get('dataCatalogueGroupID').setValue(id);
      });
  }

  createNewCategory(
    form: FormGroup,
    dialog: Dialog,
    loadDataTrigger$: BehaviorSubject<void>
  ): void {
    let instance = dialog.open(CreateCatalogueCategoryDialogComponent, {
      container: CustomDialogContainer,
      data: { dataCatalogueGroupID: -1 },
    }).componentInstance;

    instance.createEvent
      .pipe(
        filter((event: CreateEvent<CreateCatalogueCategory>) => event.create),
        switchMap((event: CreateEvent<CreateCatalogueCategory>) =>
          this.createCategory(event.model)
        )
      )
      .pipe(tap(() => loadDataTrigger$.next()))
      .subscribe((response) => {
        let parts = response.split(':');
        const id = parseInt(parts[1].trim());
        form.get('dataCatalogueCategoryID').setValue(id);
      });
  }

  generateLayer(cl: CatalogueLayer) {
    let layer: VectorLayer<any> | TileLayer<any> | VectorImageLayer<any>;
    let url: string = '';
    switch (cl.sourceType) {
      case 'TileWMS':
        layer = new TileLayer({
          source: new TileWMS({
            url: cl.url,
            params: { CRS: 'EPSG:28355', ...cl.params },
          }),
          visible: true,
          properties: {
            legendURL: `${cl.url}?REQUEST=GetLegendGraphic&VERSION=1.3.0&FORMAT=image/png&LAYER=${cl.params['LAYERS']}`,
            ...cl.params,
          },
        });
        break;
      case 'VectorImage':
      case 'Vector':
        layer = new VectorImageLayer({
          source: new VectorSource({
            url: this.processUrl(cl.url),
            format: new GeoJSON(),
          }),
          visible: true,
        });
        break;
      case 'WFS':
        layer = new VectorImageLayer({
          source: new VectorSource({
            url: `${cl.url}?service=WFS&request=GetFeature&typename=${cl.params['LAYERS']}&outputFormat=application/json`,
            format: new GeoJSON(),
          }),
          visible: true,
        });
        break;
      case 'XYZ':
        layer = new TileLayer({
          source: new XYZ({
            url: cl.url,
            projection: this.getProjection(cl.url),
            attributions: cl.attribution ? cl.attribution : '',
          }),
          visible: true,
        });
        break;
      case 'ArcGISRest':
        url = cl.url.endsWith('/')
          ? `${cl.url.substring(0, cl.url.length - 1)}`
          : `${cl.url}`;
        layer = new Tile({
          source: new TileArcGISRest({
            url: url,
            params: cl.params,
          }),
          properties: { legendURL: url + '/legend?f=json' },
        });
        break;
      case 'ArcGISFeatureService':
        url = cl.url.endsWith('/') ? `${cl.url}` : `${cl.url}/`;
        layer = new VectorLayer({
          source: new VectorSource({
            format: new GeoJSON(),
            url: `${url}${cl.params['LAYERS']}/query?f=geojson&where=1=1&outFields=*`,
          }),
          visible: true,
        });
        if (this._COLOUR_LIST_TEMP.length === 0) {
          this._COLOUR_LIST_TEMP = [...this._COLOUR_LIST];
          this._OPAQUE_COLOUR_LIST_TEMP = [...this._OPAQUE_COLOUR_LIST];
        }
        const num: number = Math.floor(
          Math.random() * this._COLOUR_LIST_TEMP.length
        );
        const colour: string = this._COLOUR_LIST_TEMP.splice(num, 1)[0];
        const opaqueColour: string = this._OPAQUE_COLOUR_LIST_TEMP.splice(
          num,
          1
        )[0];

        layer.setStyle((feature: FeatureLike) =>
          this.getFeatureStyle(feature, colour, opaqueColour)
        );
        break;
      default:
        break;
    }

    layer.set('title', cl.name);
    layer.setProperties({
      ...cl.params,
      title: cl.name,
      sourceType: cl.sourceType,
    });
    return layer;
  }

  processUrl(url: string): string {
    let processedUrl: string = url;
    if (!processedUrl.includes('outputFormat')) {
      //processedUrl = `${processedUrl}&outputFormat=application/json`;
      processedUrl = `${processedUrl}&outputFormat=GEOJSON`;
    }
    /*if (!processedUrl.includes('srsName')) {
      processedUrl = `${processedUrl}&srsName=EPSG:${this.basemapsService.default_epsg}`;
    }*/
    return processedUrl;
  }

  getProjection(url: string): string {
    const srs: string = url.split('&').filter((v) => v.includes('srsName'))[0];
    if (srs) {
      return srs;
    }
    return `EPSG: ${this.baseMapsService.default_epsg}`;
  }

  getLayerIdFromUrl(url) {
    // Split the URL by '/'
    var parts = url.split('/');

    // The layer ID is the last part of the URL
    var layerId = parts.pop();

    // Convert to integer if necessary
    return parseInt(layerId, 10);
  }

  WMSGetCapabilities(url: string) {
    return this.http
      .get<WMSCapability[]>(`${this._WMS_CAPABILITIES_ENDPOINT}${url}`)
      .pipe(
        map((data) =>
          data.filter((value) => value.crsList.includes('EPSG:3857'))
        )
      );
  }

  WFSSGetCapabilities(url: string) {
    return this.http
      .get<WFSCapability[]>(`${this._WFS_CAPABILITIES_ENDPOINT}${url}`)
      .pipe(
        map((data) => {
          return data.filter((value) => value.defaultCRS.includes('EPSG:3857'));
        })
      );
  }

  ArcGISResGetMapLayers(url: string) {
    return this.http
      .get<ArcGISMapLayers>(`${this._ARC_GIS_REST_MAP_LAYERS_ENDPOINT}${url}`)
      .pipe(map((data) => data.layers));
  }

  ArcGISRestGetFeatureLayers(url: string) {
    return this.http
      .get<ArcGISMapLayers>(
        `${this._ARC_GIS_REST_FEATURE_LAYERS_ENDPOINT}${url}`
      )
      .pipe(map((data) => data.layers));
  }

  private getFeatureStyle(
    feature: FeatureLike,
    colour: string,
    opaqueColour: string
  ): Style {
    const type: Type = feature.getGeometry().getType();
    switch (type) {
      case 'Point':
      case 'MultiPoint':
        return new Style({
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({ color: colour }),
            stroke: new Stroke({ color: 'black', width: 1 }),
          }),
        });
      case 'Polygon':
      case 'MultiPolygon':
        return new Style({
          stroke: new Stroke({
            color: colour,
            width: 2,
          }),
          fill: new Fill({
            color: opaqueColour,
          }),
        });
      case 'LineString':
      case 'MultiLineString':
        return new Style({
          stroke: new Stroke({
            color: colour,
            width: 2,
          }),
        });
      default:
        const fill: Fill = new Fill({
          color: colour,
        });
        const stroke: Stroke = new Stroke({
          color: colour,
          width: 2,
        });
        return new Style({
          image: new Circle({
            fill: fill,
            stroke: stroke,
            radius: 5,
          }),
          fill: fill,
          stroke: stroke,
        });
    }
  }
}
