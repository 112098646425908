<mat-drawer-container
  [hasBackdrop]="false"
  style="width: 100%; height: 100%"
  class="example-container"
>
  <mat-drawer
    class="sidebar-drawer"
    position="end"
    #sideNav
    mode="over"
    [opened]="route.firstChild"
  >
    <side-bar-header
      [sideNav]="sideNav"
      [pages]="sidebarService.getPages()"
      (openPage)="openPage($event)"
    ></side-bar-header>
    <div class="router-container">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <ng-content></ng-content>

    @if ({isSmall: isSmallScreen()}; as screen) {

    <div
      class="sidebar"
      [ngClass]="{
        'sidebar--bottom': screen.isSmall,
        'sidebar--right': !screen.isSmall
      }"
      [style.display]="display()"
    >
      <ul>
        @for (page of pages; track $index;) {
        <li
          class="nav-item"
          [ngClass]="{
            'sidebar-page_active': page.active,
            'last-item': $last
          }"
          appTooltip
          [appTooltip]="page.title"
          [tooltipPosition]="screen.isSmall ? 'top' : 'left'"


        >
        <button [disabled]="page.disabled"  [ngStyle]="{width:'100%', height:'100%'}" (click)="openPage(page)"><span><i [class]="page.className"></i></span></button>

        </li>
        }
      </ul>
    </div>

    }
  </mat-drawer-content>
</mat-drawer-container>
