<div #draggableContainer class="test">
  <div  draggable (heightChange)="onHeightChange($event)" class="tab">
      <button appTooltip="Attribute Table" tooltipPosition="top" (click)="close()"><span><i class="fa fa-lg fa-table"></i></span></button>
  </div>
    <p-table
    [value]="propertiesService.properties()"
    [scrollable]="true"
    [scrollHeight]="tableHeight()"
    [ngStyle]="{'height': tableHeight()}"
    [virtualScroll]="true"
     [virtualScrollItemSize]="42"
    styleClass="mt-3"
    #table
    selectionMode="single" (selectionChange)="state.selectedRow.set($event)" [selection]="state.selectedRow()" dataKey="propertyID"
    [globalFilterFields]="['propertyName', 'projectName', 'state', 'region','propertyOwnerName', 'sourceName','propertyAddress']"

    (onSort)="onSort($event)"
    >
    <ng-template pTemplate="caption">
          <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="table.clear();globalFilter.value =''"></button>

          <button class="button button--blue" (click)="exportToCSV()">Export as CSV</button>
          <span class="p-input-icon-left ml-auto">
              <i class="fa fa-search"></i>
              <input #globalFilter pInputText type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
          </span>
  </ng-template>
      <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="propertyName"  style="min-width:200px" pFrozenColumn>Property Name<p-sortIcon field="propertyName"></p-sortIcon></th>
            <th pSortableColumn="projectName" style="min-width:200px">Project Name <p-sortIcon field="projectName"></p-sortIcon></th>
            <th pSortableColumn="status" style="min-width:200px">Status <p-sortIcon field="projectName"></p-sortIcon></th>
            <th pSortableColumn="stage" style="min-width:200px">Stage <p-sortIcon field="projectName"></p-sortIcon></th>
            <th pSortableColumn="suitability" style="min-width:200px">Suitability <p-sortIcon field="projectName"></p-sortIcon></th>
            <th pSortableColumn="propertyCadastralID" style="min-width:200px">Property ID <p-sortIcon field="propertyCadastralID"></p-sortIcon></th>
            <th pSortableColumn="state" style="min-width:200px">State <p-sortIcon field="state"></p-sortIcon></th>
            <th pSortableColumn="region" style="min-width:200px">Region <p-sortIcon field="region"></p-sortIcon></th>
            <th pSortableColumn="areaHaDisplay" style="min-width:200px">Area (Ha) Title <p-sortIcon field="areaHaDisplay"></p-sortIcon></th>
            <th pSortableColumn="areaHaCalculated" style="min-width:200px">Area (Ha) Calculated <p-sortIcon field="areaHaCalculated"></p-sortIcon></th>
            <th pSortableColumn="listingDate" style="min-width:200px">Listing Date <p-sortIcon field="listingDate"></p-sortIcon></th>
            <th pSortableColumn="createdAt" style="min-width:200px">Created <p-sortIcon field="createdAt"></p-sortIcon></th>
            <th pSortableColumn="waterAllocation" style="min-width:200px">Water Allocation <p-sortIcon field="waterAllocation"></p-sortIcon></th>
            <th pSortableColumn="price" style="min-width:200px">Price <p-sortIcon field="price"></p-sortIcon></th>
            <th pSortableColumn="propertyOwnerName" style="min-width:200px">Owner/Operator <p-sortIcon field="propertyOwnerName"></p-sortIcon></th>
            <th pSortableColumn="sourceName" style="min-width:200px">Source <p-sortIcon field="sourceName"></p-sortIcon></th>
            <th pSortableColumn="updatedAt" style="min-width:200px">Updated <p-sortIcon field="updatedAt"></p-sortIcon></th>
            <th pSortableColumn="propertyAddress" style="min-width:200px">Property Address <p-sortIcon field="propertyAddress"></p-sortIcon></th>
            <th pSortableColumn="primaryClass" style="min-width:200px">Primary Class <p-sortIcon field="primaryClass"></p-sortIcon></th>
            <th pSortableColumn="secondaryClass" style="min-width:200px">Secondary Class<p-sortIcon field="secondaryClass"></p-sortIcon></th>
            <th style="min-width:200px" pFrozenColumn alignFrozen="right">Actions</th>
        </tr>

        <tr>
          <th pFrozenColumn>
              <p-columnFilter matchMode="contains"  type="text" field="propertyName"></p-columnFilter>
          </th>
          <th >
            <p-columnFilter matchMode="contains"  type="text" field="projectName"></p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="status" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect appendTo="body" [ngModel]="value" [options]="statuses()" placeholder="Any" (onChange)="filter($event.value); log($event.value)">
                    <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                            <span class="ml-1 mt-1">{{ option }}</span>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </ng-template>
        </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="stage" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect appendTo="body" [ngModel]="value" [options]="stages()" placeholder="Any" (onChange)="filter($event.value); log($event.value)">
                    <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                            <span class="ml-1 mt-1">{{ option }}</span>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </ng-template>
        </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="suitability" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect appendTo="body" [ngModel]="value" [options]="suitabilities()" placeholder="Any" (onChange)="filter($event.value); log($event.value)">
                    <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                            <span class="ml-1 mt-1">{{ option }}</span>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </ng-template>
        </p-columnFilter>
        </th>
          <th>
            <p-columnFilter  type="text" field="propertyCadastralID"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter  type="text" field="state"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter  type="text" field="region"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter  type="numeric" field="areaHaDisplay"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter  type="numeric"  field="areaHaCalculated"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter  type="date" field="listingDate">
              <ng-template pTemplate="filter" let-filter="filterCallback">
                <p-calendar appendTo="body" (onSelect)="log($event);filter($event);" dateFormat="dd/mm/yy"></p-calendar>
             </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter  type="date" field="createdAt">
              <ng-template pTemplate="filter" let-filter="filterCallback">
                <p-calendar appendTo="body" (onSelect)="log($event);filter($event);" dateFormat="dd/mm/yy"></p-calendar>
             </ng-template>
            </p-columnFilter>
          </th>
          <th></th>
          <th></th>
          <th> <p-columnFilter matchMode="contains"  type="text" field="propertyOwnerName"></p-columnFilter></th>
          <th> <p-columnFilter  matchMode="contains" type="text" field="sourceName"></p-columnFilter></th>
          <th>
            <p-columnFilter  type="date" field="updatedAt">
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <p-calendar appendTo="body" (onSelect)="log($event);filter($event);" dateFormat="dd/mm/yy"></p-calendar>
           </ng-template>
          </p-columnFilter></th>
          <th>
            <p-columnFilter  matchMode="contains" type="text" field="propertyAddress"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="primaryClass" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect appendTo="body" [ngModel]="value" [options]="classes()" placeholder="Any" (onChange)="filter($event.value); log($event.value)">
                      <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                              <span class="ml-1 mt-1">{{ option }}</span>
                          </div>
                      </ng-template>
                  </p-multiSelect>
              </ng-template>
          </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="secondaryClass" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect appendTo="body" [ngModel]="value" [options]="classes()" placeholder="Any" (onChange)="filter($event.value); log($event.value)">
                      <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                              <span class="ml-1 mt-1">{{ option }}</span>
                          </div>
                      </ng-template>
                  </p-multiSelect>
              </ng-template>
          </p-columnFilter>
          </th>
          <th pFrozenColumn alignFrozen="right"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-property>
        <tr [pSelectableRowDblClick]="property">
          <td pFrozenColumn>{{property.propertyName}}</td>
            <td>{{property.projectName}}</td>
            <td>{{property.status}}</td>
            <td>{{property.stage}}</td>
            <td>{{property.suitability}}</td>
            <td>{{property.propertyCadastralID}}</td>
            <td>{{property.state}}</td>
            <td>{{property.region}}</td>
            <td>{{property.areaHaDisplay | number:'1.0-2'}}</td>
            <td>{{property.areaHaCalculated | number:'1.0-2'}}</td>
            <td>{{property.listingDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{property.createdAt | date: 'dd/MM/yyyy'}}</td>
            <td>{{property.waterAllocation}}</td>
            <td>{{property.price | currency}}</td>
            <td>{{property.propertyOwnerName}}</td>
            <td>{{property.sourceName}}</td>
            <td>{{property.updatedAt | date: 'dd/MM/yyyy'}}</td>
            <td>{{property.propertyAddress}}</td>
            <td>{{property.primaryClass}}</td>
            <td>{{property.secondaryClass}}</td>
            <td pFrozenColumn alignFrozen="right">
              <div class="grid grid--column gap--sm">
                <button (click)="confirmDelete(property)" class="button button--danger"><span class="fa fa-trash"></span></button>
                <button (click)="openEditDialog(property)" class="button button--blue"><span class="fa fa-pencil"></span></button>
              </div>
            </td>
        </tr>
    </ng-template>
    </p-table>
</div>


