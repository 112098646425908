import { CdkDialogContainer, DialogModule } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'dialog-container',
  styles: [
    `
      :host {
        border: 1px solid #ccc;
        border-radius: 10px;
        background-color: var(--dialog-bg, #fff);
        padding: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        min-width: clamp(min(10vw, 20rem), 300px, max(90vw, 55rem));
        max-height: 95vh;
        overflow: hidden;
      }
    `,
  ],
  standalone: true,
  template: '<ng-template cdkPortalOutlet></ng-template>',
  imports: [DialogModule],
})
export class CustomDialogContainer extends CdkDialogContainer {}
