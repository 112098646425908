import { Component, EventEmitter, Output, Signal, computed, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgxColorsModule, validColorValidator } from 'ngx-colors';
import { MaterialModule } from 'src/app/material/material.module';
import { toSignal } from '@angular/core/rxjs-interop';
import { Fill, Stroke, Style } from 'ol/style';

@Component({
  selector: 'app-fill-editor',
  standalone: true,
  imports: [CommonModule,MaterialModule, NgxColorsModule, ReactiveFormsModule],
  templateUrl: './fill-editor.component.html',
  styleUrl: './fill-editor.component.css'
})
export class FillEditorComponent {

  @Output() styleChange = new EventEmitter();

  formState:Signal<{color: string, strokeColor: string, strokeWidth: number}>;


  public form: FormGroup = new FormGroup(
    {
      colorInput: new FormControl('rgba(255,255,255,0.4)', validColorValidator()),
      color: new FormControl('rgba(255,255,255,0.4)'),
      strokeWidth: new FormControl(1.25),
      strokeColorInput: new FormControl('#3399CC', validColorValidator()),
      strokeColor: new FormControl('#3399CC')
    },
    { updateOn: "change" }
  );




  constructor() {
    this.formState = toSignal(this.form.valueChanges,{});


      let fillColorTextControl = toSignal(this.form.controls["color"].valueChanges, null);
      let strokeColorTextControl = toSignal(this.form.controls["strokeColor"].valueChanges, null);

      effect(() => {
      this.form.get('colorInput').setValue(fillColorTextControl(), {emitEvent: false});
      });

      effect(() => {
        this.form.get('strokeColorInput').setValue(strokeColorTextControl(), {emitEvent: false});
      });


      let style = computed(() => {
      let state = this.formState();

      if(state == undefined)
      {
        return null;
      }

      let fill = new Fill({color: state.color});

       let stroke = new Stroke({color: state.strokeColor, width: state.strokeWidth});

       let style = new Style({ fill: fill,stroke: stroke});

       return style
     });

     this.form.valueChanges.subscribe(() => this.styleChange.emit(style()))
   }

   ngOnInit(): void {


  }


}
