import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';

@Component({
  selector: 'app-download-layer-dialog',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  templateUrl: './download-layer-dialog.component.html',
  styleUrl: './download-layer-dialog.component.css'
})
export class DownloadLayerDialogComponent {

  form: FormGroup

  constructor(public dialogRef: MatDialogRef<DownloadLayerDialogComponent>) {

    this.form = new FormGroup({
      layerFormat: new FormControl('geoJSON', [CustomValidators.required]),
      srid:new FormControl('4326', [CustomValidators.required])
    });


  }

download()
{
  this.dialogRef.close(this.form.getRawValue());
}
}
