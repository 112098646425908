<div class="title-bar">
  <div class="title">Update Catalogue Layer</div>
  <div class="window-controls">
    <button (click)="cancel()" ><span class="fa fa-times"></span></button>
  </div>
</div>
<div class="content gap--md">
  <form class="grid grid--row gap--sm" [formGroup]="form">
    <mat-form-field  appearance="outline">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput placeholder="Name">
      <mat-error *ngIf="form.get('name').errors">
        <!-- You can put your email validation error messages here -->
      </mat-error>
    </mat-form-field>
    <mat-form-field  appearance="outline">
      <mat-label>Description</mat-label>
      <textarea rows="4" cols="45" formControlName="description" matInput placeholder="description"></textarea>
      <mat-error *ngIf="form.get('description').errors">
        <!-- You can put your email validation error messages here -->
      </mat-error>
    </mat-form-field>
    <div>
      <mat-form-field  appearance="outline">
        <mat-label>Layer Type</mat-label>
        <mat-select formControlName="layerTypeID" placeholder="Select Layer Type">
          @for (lt of layerTypes$ | async; track lt) {
            <mat-option [value]="lt.id">{{lt.name}}</mat-option>
          }

        </mat-select>
      </mat-form-field>
      <mat-form-field  appearance="outline">
        <mat-label>Source Type</mat-label>
        <mat-select formControlName="sourceTypeID" placeholder="Select Source Type">
          @for (st of layerSources(); track st) {
            <mat-option [value]="st.id">{{st.name}}</mat-option>
          }

        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field  appearance="outline">
      <mat-label>Url</mat-label>
      <input formControlName="url" type="url" matInput placeholder="Url">
      <mat-error *ngIf="form.get('url').errors">
        <!-- You can put your email validation error messages here -->
      </mat-error>
    </mat-form-field>
    <!--<mat-form-field  appearance="outline">
      <mat-label>Output Format</mat-label>
      <input formControlName="outputFormat" matInput placeholder="Output Format">
      <mat-error *ngIf="form.get('outputFormat').errors">
        &lt;!&ndash; You can put your email validation error messages here &ndash;&gt;
      </mat-error>
    </mat-form-field>-->
    <mat-form-field  appearance="outline">
      <mat-label>Attribution</mat-label>
      <input formControlName="attribution" matInput placeholder="Attribution">
      <mat-error *ngIf="form.get('attribution').errors">
      </mat-error>
    </mat-form-field>

    <div class="grid--row gap--sm " formArrayName="params">
      <div class="grid--column">
        <span class="flex align-center">Attributes</span>
        <button type="button" (click)="addParam()" class="button button--blue button--action justify-self-end">
          <i class="fa fa-plus"></i>
        </button>
      </div>
      @for (item of params.controls; track $index) {
        <div class="grid--column gap--sm align-center justify-center" [formGroupName]="$index">
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <mat-label>Attribute Name</mat-label>
            <input formControlName="name" matInput>
          </mat-form-field>
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <mat-label>Attribute Value</mat-label>
            <input formControlName="value" matInput >

          </mat-form-field>
          <button [disabled]="item.get('value').disabled"  type="button" (click)="removeParam($index)" class="button button--action button--danger">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      }
    </div>
    <mat-form-field  appearance="outline">
      <mat-label>Category</mat-label>
      <mat-select formControlName="dataCatalogueCategoryID" placeholder="Select Category">
        @for (cat of categoryObservable  | async; track cat) {
          <mat-option [value]="cat.dataCatalogueCategoryID">{{cat.name}}</mat-option>
        }
        <mat-option (click)="createNewCategory()" ><i class="fa fa-plus"></i> Add Category</mat-option>
        <mat-error *ngIf="form.get('dataCatalogueCategoryID').errors">
          <!-- You can put your email validation error messages here -->
        </mat-error>
      </mat-select>
    </mat-form-field>
  </form>
  <div>
    <div #miniMap class="min-map" ></div>
  </div>
</div>

<div class="footer">
  <button type="button"  class="button button--muted" (click)="validate()">Validate Service</button>
  <button type="button"  class="button button--muted" (click)="cancel()">Cancel</button>
  <button type="button" class="button button--blue" (click)="submit()"><span class="fa fa-save button-icon"></span>Submit</button>
</div>





