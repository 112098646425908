import {
  Component,
  computed,
  ElementRef,
  EventEmitter,
  Inject,
  inject,
  OnInit,
  Output,
  Signal,
  ViewChild,
} from '@angular/core';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CustomValidators } from '../../../../../../../../shared/extensions/CustomValidators';
import { CommonModule } from '@angular/common';
import { UpdateCatalogueLayer } from '../../../data-access/models/create-update-catelogue.model';
import { DataCatalogueService } from '../../../../../data-access/services/data-catalogue.service';
import { MiniMapService } from '../../../data-access/services/mini-map.service';
import { BehaviorSubject, filter, map, startWith, switchMap } from 'rxjs';
import { CreateEvent } from '../../../../../../../../@core/events/createEvent';
import { MaterialModule } from '../../../../../../../../material/material.module';
import { CatalogueLayer } from '../../../../../data-access/models/catalogue-data.model';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-update-catalogue-layer-dialog',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule],
  templateUrl: './update-catalogue-layer-dialog.component.html',
  styleUrl: './update-catalogue-layer-dialog.component.css',
})
export class UpdateCatalogueLayerDialogComponent implements OnInit {
  @Output() updateEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('miniMap', { static: true }) miniMap!: ElementRef;

  readonly catalogueService: DataCatalogueService =
    inject(DataCatalogueService);
  private readonly mapService: MiniMapService = inject(MiniMapService);
  private loadDataTrigger$: BehaviorSubject<void> = new BehaviorSubject<void>(
    undefined
  );
  private readonly dialog: Dialog = inject(Dialog);
  form: FormGroup;

  categoryObservable = this.loadDataTrigger$.pipe(
    switchMap(() => this.catalogueService.loadCatalogueCategories())
  );

  catalogueLayerToUpdate: CatalogueLayer;
  layerSources$;
  layerSources: Signal<any[]>;
  layerTypes$ = this.catalogueService.loadCatalogueLayerTypes();

  ngOnInit(): void {
    this.mapService.setTarget(this.miniMap.nativeElement);
  }

  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) protected data: CatalogueLayer
  ) {
    this.catalogueLayerToUpdate = data;
    console.log(data);
    this.form = new FormGroup({
      name: new FormControl(this.catalogueLayerToUpdate.name, [
        CustomValidators.required,
      ]),
      description: new FormControl(this.catalogueLayerToUpdate.description, []),
      url: new FormControl(this.catalogueLayerToUpdate.url, [
        CustomValidators.required,
      ]),
      outputFormat: new FormControl('', []),
      attribution: new FormControl(this.catalogueLayerToUpdate.attribution, [
        CustomValidators.required,
      ]),
      layerTypeID: new FormControl(this.catalogueLayerToUpdate.layerTypeID, [
        CustomValidators.required,
      ]),
      sourceTypeID: new FormControl(this.catalogueLayerToUpdate.sourceTypeID, [
        CustomValidators.required,
      ]),
      dataCatalogueCategoryID: new FormControl(
        this.catalogueLayerToUpdate.dataCatalogueCategoryID,
        [CustomValidators.required]
      ),
      params: new FormArray([]),
    });

    if (this.catalogueLayerToUpdate.params) {
      console.log(this.catalogueLayerToUpdate.params);
      Object.keys(this.catalogueLayerToUpdate.params).forEach((key) =>
        this.addParam(key, this.catalogueLayerToUpdate.params[key])
      );
    }

    const sourceTypeChanges = toSignal(
      this.form.get('sourceTypeID').valueChanges
    );

    const selectedSourceType = computed(() =>
      this.layerSources().find((src) => src.id == sourceTypeChanges())
    );

    this.layerSources$ = this.form.get('layerTypeID').valueChanges.pipe(
      startWith(this.data.layerTypeID),
      filter((val) => val != null && val > -1),
      switchMap((selectedType) => {
        return this.catalogueService
          .loadCatalogueLayerSources()
          .pipe(map((data) => ({ selectedType, data })));
      }),
      map(({ selectedType, data }) =>
        data.filter((data) => data.layerTypeID == selectedType)
      )
    );

    this.layerSources = toSignal(this.layerSources$, { initialValue: [] });
  }

  createNewCategory() {
    this.catalogueService.createNewCategory(
      this.form,
      this.dialog,
      this.loadDataTrigger$
    );
  }

  submit() {
    let model: UpdateCatalogueLayer = {
      id: this.catalogueLayerToUpdate.dataCatalogueLayerID,
      Name: this.form.get('name').value,
      Description: this.form.get('description').value,
      Url: this.form.get('url').value,
      Attribution: this.form.get('attribution').value,
      LayerTypeID: this.form.get('layerTypeID').value,
      SourceTypeID: this.form.get('sourceTypeID').value,
      DataCatalogueCategoryID: this.form.get('dataCatalogueCategoryID').value,
    };

    model['params'] = this.params.controls.reduce((acc, formGroup) => {
      const name = formGroup.get('name').value;
      const value = formGroup.get('value').value;

      acc[name] = value;
      return acc;
    }, {});

    this.updateEvent.emit(CreateEvent.create<UpdateCatalogueLayer>(model));
    this.dialogRef.close();
  }

  cancel(): void {
    this.updateEvent.emit(CreateEvent.cancel<UpdateCatalogueLayer>());
    this.dialogRef.close();
  }

  validate(): void {
    this.mapService.addAndCheckNewLayer(
      this.form.get('url').value,
      this.form.get('sourceTypeID').value,
      this.form.get('name').value
    );
  }

  get params() {
    return this.form.get('params') as FormArray;
  }

  addParam(name = null, value = null) {
    const attributeFormGroup = new FormGroup({
      name: new FormControl(name, [CustomValidators.required]),
      value: new FormControl(value, [CustomValidators.required]),
    });
    this.params.push(attributeFormGroup);
  }

  removeParam(index: number) {
    this.params.removeAt(index);
  }
}
