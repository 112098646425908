import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';
import { CalculateLength, ConvertFeatureToWKT } from 'src/app/shared/helpers/transformations';

@Component({
  selector: 'app-create-feature-dialog',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  templateUrl: './create-feature-dialog.component.html',
  styleUrl: './create-feature-dialog.component.css'
})
export class CreateFeatureDialogComponent {

  form: FormGroup


  /**
   *
   */
  constructor(public dialogRef: MatDialogRef<CreateFeatureDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: {feature:Feature<any> }) {

    this.form = new FormGroup({
      name: new FormControl(null, [CustomValidators.required]),
      attributes:new FormArray([])
    });


   switch(data.feature.getGeometry().getType())
   {
    case 'LineString':
      this.calculateLength();
      break;
    case 'Point':
      break;
    default:
   }

  }

  get attributes() {
    return this.form.get('attributes') as FormArray;
  }

  calculateLength()
  {
    let length = CalculateLength(this.data.feature, {projection:'EPSG:3857'})
    const attributeFormGroup = new FormGroup({
      name: new FormControl({value: 'FEAT_LENGTH_M', disabled: true}, [CustomValidators.required]),
      value: new FormControl({value: length.toString(), disabled: true}, [CustomValidators.required])
    });
    this.attributes.push(attributeFormGroup);
  }

  addAttribute() {
    const attributeFormGroup = new FormGroup({
      name: new FormControl(null, [CustomValidators.required]),
      value: new FormControl(null, [CustomValidators.required])
    });
    this.attributes.push(attributeFormGroup);
  }

  removeAttribute(index: number) {
    this.attributes.removeAt(index);
  }

  onSubmit()
  {

    let model = {};

    for (let key of Object.keys(this.form.controls)) {
      model[key] = this.form.controls[key].getRawValue();
    }


    model['attributes'] =this.attributes.controls.reduce((acc, formGroup) => {

      const name = formGroup.get('name').value;
      const value = formGroup.get('value').value;


      acc[name] = value;
      return acc;
    }, {});
    model['wkt'] = ConvertFeatureToWKT(this.data.feature);

     this.dialogRef.close(createResult.create(model));
  }

  cancel()
{
  this.dialogRef.close(createResult.cancel());
}

}




export class createResult {
  shouldCreate: boolean = false;
  model


  constructor(shouldCreate, model) {

    this.shouldCreate = shouldCreate;
    this.model = model;
  }

  static create = (model) => new createResult(true, model);

  static cancel = () => new createResult(false, null);
}
