import {
  Component,
  computed,
  EventEmitter,
  Inject,
  inject,
  Output,
  Signal,
} from '@angular/core';
import { DataCatalogueService } from '../../../../../data-access/services/data-catalogue.service';
import { MapLayer } from '../../../../../data-access/models/getcapabilities.model';
import { FormControl } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatError } from '@angular/material/form-field';
import { SharedModule } from '../../../../../../../../shared/shared.module';
import { MatOption } from '@angular/material/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { CreateEvent } from '../../../../../../../../@core/events/createEvent';
import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';
import { NgClass } from '@angular/common';
import { TooltipDirective } from '../../../../../../../../shared/directives/tooltip/tooltip.directive';
import { CatalogueCategoriesComponent } from '../../../../../ui/catalogue-categories/catalogue-categories.component';

@Component({
  selector: 'app-list-arc-gis-map-layers',
  standalone: true,
  imports: [
    MatError,
    MatRadioButton,
    SharedModule,
    MatOption,
    MatRadioGroup,
    InputTextModule,
    CdkAccordionItem,
    CdkAccordion,
    NgClass,
    TooltipDirective,
    CatalogueCategoriesComponent,
  ],
  templateUrl: './list-arc-gis-map-layers.component.html',
  styleUrl: './list-arc-gis-map-layers.component.css',
})
export class ListArcGisMapLayersComponent {
  readonly catalogueService: DataCatalogueService =
    inject(DataCatalogueService);

  @Output() selectionEvent: EventEmitter<any> = new EventEmitter();
  layers: Signal<MapLayer[]>;
  selectedLayer: MapLayer;
  searchField: FormControl = new FormControl('');
  filteredLayers: Signal<MapLayer[]>;

  constructor(public dialogRef: DialogRef<string>, @Inject(DIALOG_DATA) data) {
    this.layers = toSignal(
      this.catalogueService.ArcGISResGetMapLayers(data.url),
      { initialValue: null }
    );

    const searchFieldChanges = toSignal(
      this.searchField.valueChanges.pipe(map((value) => value.toLowerCase())),
      {
        initialValue: '',
      }
    );

    this.filteredLayers = computed(() =>
      this.layers().filter((l) =>
        l.name.toLowerCase().includes(searchFieldChanges())
      )
    );
  }

  cancel(): void {
    this.selectionEvent.emit(CreateEvent.cancel<any>());
    this.dialogRef.close();
  }

  setSelection(): void {
    this.selectionEvent.emit(CreateEvent.create<MapLayer>(this.selectedLayer));
    this.dialogRef.close();
  }

  get isDisabled(): boolean {
    return !this.selectedLayer;
  }
}
