import { Component, NgZone, OnInit, Signal, ViewChild, computed, effect, inject } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';

import { SidebarService } from './data-access/sidebar.service';
import { MatDrawer } from '@angular/material/sidenav';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Observable, combineLatest, map, tap } from 'rxjs';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'src/app/material/material.module';
import { HeaderComponent } from './features/header/header.component';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';


@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  standalone:true,
  imports:[SharedModule, MaterialModule, RouterModule, HeaderComponent, CommonModule, TooltipDirective]
})
export class SidebarComponent implements OnInit {

  @ViewChild('sideNav', {static:true}) drawer: MatDrawer;

  protected readonly breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  isSmallScreen$: Observable<boolean>;

  isSmallScreen: Signal<boolean>;

  pages;

  display;

  constructor(private zone: NgZone, private router:Router, public route:ActivatedRoute, public sidebarService:SidebarService)
  {

    this.isSmallScreen$ = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(map((state: BreakpointState) => state.matches));

    this.isSmallScreen = toSignal(this.isSmallScreen$, {initialValue:false});


   this.display = computed(()=>{
    return this.isSmallScreen() ? 'none' : 'grid'
   });

   this.pages = Array.from(this.sidebarService.getPages(), (v) => v[1])

  }

  ngOnInit(): void {
      this.sidebarService.setDrawer(this.drawer)
  }

  openPage(page)
  {



    if(this.router.url.startsWith( `/map/${page.route.replace(":id", "")}`))
    {
      if(this.drawer.opened)
      {
        this.router.navigate(['map'])
        page.active = false;
      }
      else
      {
       this.sidebarService.open()
      }

    }

    else
    {
      let urlSegments = this.router.url.split('/');


      let activePage = this.sidebarService.getPages().get(urlSegments[urlSegments.length - 1]);

      if(activePage)
      activePage.active = false;

      this.router.navigate([`/map/${page.route}`])
      page.active = true;
    }


  }


}
