import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import RenderFeature from 'ol/render/Feature';
import { Style, Fill, Stroke, Icon, Text } from 'ol/style';
import {
  ConvertFeatureToWKT,
  CovertWKTFeature,
} from 'src/app/shared/helpers/transformations';

let styleCache: Map<string, Style | Style[]> = new Map();

export const boundaryStyle = new Style({
  stroke: new Stroke({
    color: '#6B8E23', // olive green
    width: 2,
  }),
});

export function getPaddockStyle(
  feature: Feature<any> | RenderFeature,
  resolution,
  filter
) {
  let iconUrl;
  let scale = 1;

  let classID = feature.getProperties()['primaryClassID'];

  if (resolution > 1300) {
    scale = 0.65;
  }

  iconUrl = getPropertyIcon(classID, resolution);

  return new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      src: iconUrl,
      scale: scale,
    }),
  });
}

export function getAreaStyle(
  feature: Feature<any> | RenderFeature,
  resolution,
  filter
) {
  let iconUrl;
  let scale = 1;

  let area = feature.getProperties()['area'];
  area = area ? area : '';

  const textStyle = new Text({
    text: `${area.toString()} (Ha)`,
    font: '14px Calibri,sans-serif',
    fill: new Fill({ color: '#000' }),
    stroke: new Stroke({ color: '#fff', width: 2 }),
    offsetX: 0,
    offsetY: 0,
    textAlign: 'center',
    textBaseline: 'middle',
    rotation: 0,
    scale: 1,
  });

  // Create and return the style with the text included
  return new Style({
    text: textStyle,
  });
}

function getPropertyIcon(primaryClassID, resolution) {
  let zoomLevel =
    resolution > 40
      ? 'lowZoom'
      : resolution > 5 && resolution <= 40
      ? 'medZoom'
      : 'highZoom';
  return `assets/icons/property/${primaryClassID}/${zoomLevel}.png`;
}
