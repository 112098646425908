<cdk-accordion class="dc-accordion">
  @for (item of items; track item; let index = $index) {
    <cdk-accordion-item
      #accordionItem="cdkAccordionItem"
      class="dc-accordion-item"
      role="button"
      tabindex="0"
      [attr.id]="'accordion-header-' + index"
      [attr.aria-expanded]="accordionItem.expanded"
      [attr.aria-controls]="'accordion-body-' + index">

      <div class="dc-accordion-item-header" (click)="accordionItem.toggle()" [ngClass]="{'sticky-header': accordionItem.expanded}">
        {{ item }}
        <style-renderer #renderer [kind]="item"></style-renderer>
        <span class="dc-accordion-item-description">

          @if (accordionItem.expanded) {
            <i class="fa-solid fa-chevron-up"></i>
          }
          @else {
            <i class="fa-solid fa-chevron-down"></i>
          }

        </span>

        </div>
      <div
        class="dc-accordion-item-body"
        role="region"
        [@slideInOut]="accordionItem.expanded ? 'in' : 'out'"
        [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index">

        @switch (item) {
          @case ('Point') {
            <app-point-editor (styleChange)="updateStyle(item,$event);renderer.style = $event"></app-point-editor>
          }
          @case ('LineString') {
            <app-line-editor (styleChange)="updateStyle(item,$event);renderer.style = $event"></app-line-editor>
          }

          @case ('Polygon') {
            <app-fill-editor (styleChange)="updateStyle(item,$event);renderer.style = $event"></app-fill-editor>
          }

        }
      </div>
    </cdk-accordion-item>
  }
</cdk-accordion>
