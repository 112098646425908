import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from 'src/app/@core/Auth/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
   standalone: true,
   imports: [CommonModule]
})
export class ProfileComponent implements OnInit {

  private readonly authService:AuthService = inject(AuthService);

  constructor() { }

  ngOnInit() {
  }


  logout() {
    this.authService.logout().subscribe();
  }
}
