<div class="d-flex create-property-group">
  <button class="button button--blue" (click)="createByDigitize()">
    <span class="fa fa-pencil button-icon"></span>
    Digitize Property Boundary
  </button>
  <button class="button button--blue" (click)="createFromCadaster()">
    <span class="fa fa-location-dot button-icon"></span>
    Create From Cadastre
  </button>
</div>
