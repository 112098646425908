import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  effect,
  inject,
  input,
  viewChild,
} from '@angular/core';
import BaseLayer from 'ol/layer/Base';
import { state } from '../../data-access/services/layers.service';
import { CommonModule } from '@angular/common';
import { layersControl } from '../../utils/layers.control';
import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { accordionAnimation2 } from '../../../data-catalogue/utils/animations/accordion.animation';
import { CdkDragHandle } from '@angular/cdk/drag-drop';
import { LayerOpacityDirective } from '../../utils/layer-opacity.directive';
import { LayerVisibilityDirective } from '../../utils/layer-visibility.directive';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { catchError, filter, map, of, switchMap, throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-image-layer',
  templateUrl: './image-layer.component.html',
  styleUrls: ['./image-layer.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CdkAccordionModule,
    CdkDragHandle,
    LayerOpacityDirective,
    LayerVisibilityDirective,
  ],
  animations: [accordionAnimation2],
})
export class ImageLayerComponent extends layersControl implements OnInit {
  private readonly http: HttpClient = inject(HttpClient);
  layerID = uuidv4();
  layer = input<state>();

  accordion = viewChild(CdkAccordionItem);

  accordionEffect = effect(() => (this.accordion().expanded = false));

  legendObservable$ = toObservable(this.layer).pipe(
    filter(
      ({ sourceType, legendURL }) =>
        sourceType === 'ArcGISRest' && legendURL != null
    ),
    switchMap(({ legendURL, featureProperties }) =>
      this.http
        .get<any>(`${environment.apiUrl}data-catalogue/proxy?url=${legendURL}`)
        .pipe(
          catchError((error) => {
            console.error('Error fetching legend:', error);
            return of(JSON.stringify({ layers: [] }));
          }),
          map((response) => ({ response, featureProperties }))
        )
    ),
    map(({ response, featureProperties }) => {
      const layersToSelect = featureProperties['layers'];
      const parsedResponse = JSON.parse(response);
      const legend = [];

      const processLegendItems = (legendItems) => {
        legendItems.forEach(({ label, imageData }) => {
          legend.push({
            label,
            imageSrc: `data:image/png;base64,${imageData}`,
          });
        });
      };

      if (layersToSelect == null) {
        parsedResponse.layers.forEach(({ legend }) =>
          processLegendItems(legend)
        );
      } else {
        const layerId = parseInt(layersToSelect.split(':')[1]);
        const legendDefinition = parsedResponse.layers.find(
          ({ layerId: id }) => id === layerId
        );
        if (legendDefinition) {
          processLegendItems(legendDefinition.legend);
        }
      }

      return legend;
    })
  );

  legend = toSignal(this.legendObservable$);

  lastLayerAdded: number = 0;

  arcGISLegend: { imageSrc: string; label: string }[] = [];
  ngOnInit() {}

  ngAfterViewInit(): void {}
}
