<app-draw-features-overlay></app-draw-features-overlay>

<div class="layer-panel-container">
  <div class="layer-panel-header">
    <span>Feature Layers</span>
    <button (click)="createLayer()" class="add-button">
      +
    </button>
  </div>
  <div class="layer-panel-content">
    <cdk-accordion
      [multi]="true"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
      class="feature-layer-accordion"
    >

      @for (layer of featureLayers(); track layer.layerID) {
      @if(featureLayerService.layerSate.get(layer.layerID)(); as layerState) {
      <cdk-accordion-item
        #accordionItem="cdkAccordionItem"
        class="feature-layer-accordion-item"
        role="button"
        tabindex="0"
        [attr.id]="'feature-layer-accordion-header-' + layer.layerID"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'feature-layer-accordion-body-' + layer.layerID"
        (expandedChange)="onExpandedChange(accordionItem, layer.layerID)"
        [expanded]="layerState.expanded"
        cdkDrag
      >
        <div
          (click)="featureLayerService.selectLayer(layer.layerID)"
          class="feature-layer-accordion-item-header"
        >
          <label class="custom-radio">
            <input
              #input
              type="radio"
              [checked]="layerState.selected"
              name="layers"
            />
          </label>
          @if (layer.features.length > 0) {
          <button
            (click)="accordionItem.toggle()"
            class="feature-layer-accordion-item-button"
          >
            @if (accordionItem.expanded) {
            <i class="fa fa-chevron-right"></i>
            } @else {
            <i class="fa fa-chevron-down"></i>
            }
          </button>
          } @else {
          <span></span>
          }

          <span
            [ngClass]="{
              'layer-selected': layerState.selected,
              'layer-hidden': !layerState.visible
            }"
            class="feature-layer_name"
            >{{ layer.name }}</span
          >
          <style-renderer></style-renderer>
          <div class="actions">
            <button
              (click)="
                featureLayerService.toggleVisibility(layer.layerID, $event)
              "
              class="feature-layer-accordion-item-button"
            >
              @if (layerState.visible) {
              <i class="fa fa-eye"></i>
              } @else {
              <i class="fa fa-eye-slash"></i>
              }
            </button>

            @if (layerState.isEditing) {
            <button
              (click)="featureLayerService.saveChanges(layer.layerID)"
              class="feature-layer-accordion-item-button"
            >
              <i class="fa fa-check"></i>
            </button>
            <button
              (click)="featureLayerService.cancelEdit(layer.layerID)"
              class="feature-layer-accordion-item-button"
            >
              <i class="fa fa-xmark"></i>
            </button>
            } @else {
            <button
              (click)="featureLayerService.editLayer(layer.layerID)"
              class="feature-layer-accordion-item-button"
            >
              <i class="fa fa-arrows"></i>
            </button>
            }
            <button
              class="feature-layer-accordion-item-button"
              (click)="downloadFile(layer.layerID)"
            >
              <i class="fa fa-download"></i>
            </button>
            <button
              [cdkMenuTriggerFor]="menu"
              class="feature-layer-accordion-item-button"
            >
              <i class="fa fa-ellipsis-vertical"></i>
            </button>

            <ng-template #menu>
              <div class="example-menu" cdkMenu>
                <button
                  class="example-menu-item"
                  (click)="deleteLayer(layer)"
                  cdkMenuItem
                >
                  Delete
                </button>
              </div>
            </ng-template>
          </div>
        </div>
        <div
          class="feature-layer-accordion-item-body"
          role="region"
          [style.display]="accordionItem.expanded ? '' : 'none'"
          [attr.id]="'feature-layer-accordion-body-' + layer.layerID"
          [attr.aria-labelledby]="
            'feature-layer-accordion-header-' + layer.layerID
          "
        >
          @for (feature of layer.features; track feature.featureID) {

          <div>
            <span>{{ feature.name }}</span>
          </div>
          }
        </div>
      </cdk-accordion-item>
      } }
    </cdk-accordion>
  </div>
</div>



